<template>
  <div class="navbar f jcb aic pt05 pb05 pl1 pr1 bg-white-1">
    <div 
        class="navbar-item f fdc aic jcc align-c"
        v-for="(item, index) in dataNav" 
        :key="index"
    >
        <router-link :to="item.path">
            <span 
                class="navbar-item__logo icon mb025"
                :class="item.icon"
            />
            <div class="p-navsmall gray navbar-item__text">{{ item.titlecontent }}</div>
        </router-link>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            dataNav : [
                {
                    titlecontent : 'Đơn hàng',
                    icon: 'icon--order',
                    path: '/quan-ly-order'
                },
                {
                    titlecontent : 'Ví BZIE',
                    icon: 'icon--cart',
                    path: '/quan-ly-vi-BZI'
                },
                {
                    titlecontent : 'Thông báo',
                    icon: 'icon--noti',
                    path: '/quan-li-noti'
                },
                {
                    titlecontent : 'Tài khoản',
                    icon: 'icon--account',
                    path: '/quan-li-account'
                }
            ]
        }
    }
}
</script>

<style scoped lang="scss">
    @import 'NavBar.scss';
</style>
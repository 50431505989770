<template>
  <div class="auth f aic jcc w100 h100vh">
    <div
      class="login relative f fdc jcb pt2 pb1 pl1 pr1 border-radius bg-white-1"
    >
      <div>
        <div class="w100 f aic jcc mt2 mb3">
          <div class="logo-bzi-small">
            <img class="w100" src="@/assets/loginBZI.svg" />
          </div>
        </div>
        <div class="title h2 w100 mb1">Đăng nhập</div>
        <form>
          <div class="f fdc mb1">
            <label class="p4 mb05" for="">Số điện thoại</label>
            <input
              class="h6"
              type="text"
              placeholder="Nhập số điện thoại vào đây"
              v-model="username"
            />
          </div>
          <div class="f fdc mb1">
            <label class="p4 mb05" for="">Mật khẩu</label>
            <input
              class="h6"
              type="password"
              placeholder="Nhập mật khẩu vào đây"
              v-model="password"
            />
          </div>
          <div class="f jcb aic">
            <div class="f aic">
              <input type="checkbox" name="" id="" />
              <p class="ml05 h6">Nhớ tài khoản</p>
            </div>
            <div
              class="cursor"
              style="text-decoration-line: underline"
              @click="handleForgot"
            >
              <p class="h6 gray">Quên mật khẩu</p>
            </div>
          </div>
          <button
            class="w100 h5 pt075 pb075 align-c border-radius bg-red-1 white"
            style="margin-top: 2em"
            @click.prevent="login"
          >
            Đăng nhập
          </button>
        </form>
      </div>
      <div class="w100 align-c mt15 mb1">
        <span class="gray h5">Chưa có tài khoản?</span>
        <span @click="handleRegister" class="cursor blue h5">
          Đăng ký ngay</span
        >
      </div>
    </div>

    <div class="register f fdc jcb pb1 pl1 pr1 border-radius bg-white-1">
      <div>
        <div class="register__btn-back align-c pt1 pb075">
          <div @click="handleBack" class="f aic align-l cursor">
            <svg class="mr025" viewBox="0 0 7 10" width="7" height="10">
              <use
                xlink="http://www.w3.org/2000/svg"
                href="#back"
                x="0"
                y="0"
              ></use>
            </svg>
            <div class="h5">Quay lại</div>
          </div>
        </div>
        <div class="w100 f aic jcc mt2 mb3">
          <div class="logo-bzi-small">
            <img class="w100" src="@/assets/loginBZI.svg" />
          </div>
        </div>
        <div class="title h2 w100 mb1">Đăng ký</div>
        <!-- <div class="step relative f jcb aic mb2">
          <div class="step-line"></div>
          <div class="f fdc aic" style="height: 100px">
            <div class="step-circle step-control f jcc aic h3 mb05 border bg-white-1">
              1
            </div>
            <div class="step-title h7 align-c red-1">
              Thông tin <br />
              cá nhân
            </div>
          </div>
          <div class="f fdc aic" style="height: 100px">
            <div class="step-circle f jcc aic h3 mb05 border gray bg-white-1">
              2
            </div>
            <div class="step-title h6 gray">
              Thông tin <br />
              tài khoản
            </div>
          </div>
          <div class="f fdc aic " style="height: 100px">
            <div class="step-circle f jcc aic h3 mb05 border gray bg-white-1">
              3
            </div>
            <div class="step-title h6 gray">Hoàn thành</div>
          </div>
        </div> -->
        <!-- Đăng ký bước 1 -->
        <form class="person-info" action="">
          <div class="f fdc mb1">
            <label class="p4 mb05">Số điện thoại(Tên đăng nhập)</label>
            <input class="h6 w100" v-model="newPhone" type="number" placeholder="Nhập số điện thoại của bạn"/>
          </div>
          <div class="f fdc mb1">
            <label class="p4 mb05">Email</label>
            <input class="h6 w100" v-model="newEmail" type="email" placeholder="Nhập địa chỉ email của bạn"  />
          </div>
          <div class="f fdc mb1">
            <div class="p4 mb05">Mật khẩu</div>
            <div class="form__password js-form-password">
              <input v-model="newPassword" type="password" placeholder="Nhập mật khẩu" class="w100" />
              <span @click="seePassword($event)" class="icon icon--close-eye"/>
              <span @click="hidePassword($event)" class="icon icon--open-eye"/>
            </div>
          </div>
          <div class="f fdc mb1">
            <div class="p4 mb05">Xác nhận lại mật khẩu</div>
            <div class="form__password js-form-password">
              <input v-model="againPassword" type="password" placeholder="Nhập lại mật khẩu" class="w100"/>
              <span @click="seePassword($event)" class="icon icon--close-eye"/>
              <span @click="hidePassword($event)" class="icon icon--open-eye"/>
            </div>
          </div>
          <button  class="w100 h5 mt05 pt075 pb075 align-c border-radius bg-red-1 white" @click="handleViewNotiOTP">
            Đăng ký
          </button>
        </form>
        <!-- Đăng ký bước 2 -->
        <!-- <form class="acc-info hide" action="">
          <div class="f fdc mb1">
            <label class="h7 mb05" for="">Số điện thoại (Tên đăng nhập)</label>
            <input
              class="h6"
              v-model="username"
              type="text"
              placeholder="Nhập số điện thoại vào đây"
            />
          </div>
          <div class="f fdc mb1">
            <label class="h7 mb05" for="">Email</label>
            <input  class="h6" v-model="email" type="text" placeholder="Nhập địa chỉ email của bạn "/>
          </div>
          <div class="relative f fdc mb1">
            <div @click="handleViewPassWord" class="icon__input absolute hide">
              <div class="absolute icon"></div>
            </div>
            <div @click="handleViewPassWord" class="icon__input absolute">
              <div class="absolute icon"></div>
            </div>
            <label class="h7 mb05" for="">Mật khẩu</label>
            <input class="h6" v-model="password" type="password" placeholder="Nhập mật khẩu vào đây" />
          </div>
          <div class="relative f fdc mb2">
            <div @click="handleViewPassWord" class="icon__input absolute hide">
              <div class="absolute icon icon--open-eye"></div>
            </div>
            <div @click="handleViewPassWord" class="icon__input absolute">
              <div class="absolute icon icon--close-eye"></div>
            </div>
            <label class="h7 mb05" for="">Xác nhận lại mật khẩu</label>
            <input class="h6" v-model="passwordConfirm" type="password" placeholder="Nhập lại mật khẩu"/>
          </div>
          <button
            class="w100 h5 mt2 pt075 pb075 align-c border-radius bg-red-1 white"
            @click="handleViewOTP"
          >
            Đăng ký
          </button>
        </form> -->
        <!-- <form class="acc-accuracy hide" action="">
          <div class="f fdc">
            <label class="p4 mb05" for="">Mã OTP</label>
            <div class="code-confirm w100 f jcb aic">
              <input type="number" id="input-1" />
              <input type="number" id="input-2" />
              <input type="number" id="input-3" />
              <input type="number" id="input-4" />
              <input type="number" id="input-5" />
              <input type="number" id="input-6" />
            </div>
            <button
              class="w100 h5 mt2 pt075 pb075 align-c border-radius bg-red-1 white"
              @click="handleViewNotiOTP"
            >
              Xác thực
            </button>
          </div>
        </form> -->
      </div>
      <div class="mt1 align-c pl05 pr05 p4 mb5 h6 gray">
        Bằng việc chọn "Đăng ký", bạn đồng ý với các
        <a class="blue h7" href="">Điều khoản và Điều kiện</a> của chúng tôi
      </div>
      <div class="w100 align-c mt15 mb1">
        <span class="gray h5">Đã có tài khoản?</span>
        <span @click="handleLogin" class="cursor blue h5"> Đăng nhập ngay</span>
      </div>
    </div>

    <div class="forgot-password relative f fdc pb1 border-radius bg-white-1">
      <div class="align-c pl1 pr1 mt1 mb15 border-bottom">
        <div @click="handleBack" class="f aic mb05 align-l cursor">
          <svg class="mr025" viewBox="0 0 7 10" width="7" height="10">
            <use
              xlink="http://www.w3.org/2000/svg"
              href="#back"
              x="0"
              y="0"
            ></use>
          </svg>
          <div class="p4">Quay lại</div>
        </div>
      </div>
      <div class="pl1 pr1">
        <div class="mb15 h2">Quên mật khẩu</div>
        <div class="step relative f jcb aic mb2">
          <div class="step-line"></div>
          <div class="f fdc aic" style="height: 100px">
            <div
              class=" step-circle step-control f jcc aic h3 mb05 border bg-white-1">
              1
            </div>
            <div class="step-title brown">
              Xác thực <br />
              thông tin
            </div>
          </div>
          <div class="f fdc aic" style="height: 100px">
            <div class="step-circle f jcc aic h3 mb05 border bg-white-1">2</div>
            <div class="step-title">Tạo mật khẩu mới</div>
          </div>
          <div class="f fdc aic" style="height: 100px">
            <div class="step-circle f jcc aic h3 mb05 border bg-white-1">3</div>
            <div class="step-title">Hoàn thành</div>
          </div>
        </div>
        <form class="auth-info" action="">
          <div class="f fdc mb1 ">
            <label class="p4 mb05" for="">Email</label>
            <div class="relative f aic jce">
              <input
              class="h6 w100 "
              type="text"
              v-model="emailForgot"
              placeholder="Nhập địa chỉ email của bạn"
              />
              <div class="sentOTP absolute"    @click="sentEmailForgot">
                <p class="blue h7 mr1">Gửi OTP</p>
              </div>
             
            </div>
          </div>
          <div class="f fdc mb2">
            <label class="p4 mb05" for="">Mã OTP</label>
              <div class="code-confirm w100 f jcb aic mt05">
                <input type="number" v-model="numFogot1" id="f1" min=0 max=9  maxlength="1" v-on:keyup="move('','f1','f2')" />
                <input type="number" v-model="numFogot2" id="f2" min=0 max=9  maxlength="1" v-on:keyup="move('f1','f2','f3')"/>
                <input type="number" v-model="numFogot3" id="f3" min=0 max=9  maxlength="1" v-on:keyup="move('f2','f3','f4')"/>
                <input type="number" v-model="numFogot4" id="f4" min=0 max=9  maxlength="1" v-on:keyup="move('f3','f4','f5')" />
                <input type="number" v-model="numFogot5" id="f5" min=0 max=9  maxlength="1" v-on:keyup="move('f4','f5','f6')" />
                <input type="number" v-model="numFogot6" id="f6" min=0 max=9  maxlength="1" v-on:keyup="move('f5','f6','')" />
            </div>
          </div>
          <button
            class="w100 h5 pt075 pb075 align-c border-radius bg-red-1 white"
            v-if="enableSubmit"
            @click="handleToNewPassword"
          >
            Xác nhận mã OTP
          </button>
          <fieldset
            class="w100 h5 pt075 pb075 align-c border-radius c-red-faild white"
            v-if="disableSubmit"
            disabled
          >
                <p class=" h7 mr1">Xác nhận mã OTP</p>
          </fieldset>
        </form>
        <form class="new-password hide" action="">
          <div class="f fdc mb1">
            <label class="p4 mb05" for="">Mật khẩu mới</label>
            <input
              class="h6"
              type="text"
              v-model="newForgotPassword"
              placeholder="Nhập mật khẩu mới của bạn"
            />
          </div>
          <div class="f fdc mb2">
            <label class="p4 mb05" for="">Xác nhận mật khẩu mới của bản</label>
            <input
            v-model="againForgotPassword"
              class="h6"
              type="password"
              placeholder="Nhập mật khẩu vào đây"
            />
          </div>
          <button
            class="w100 h5 pt075 pb075 align-c border-radius bg-red-1 white"
            @click="handleToComplate"
          >
            Tạo mật khẩu mới
          </button>
        </form>
        <div class="complate hide">
          <div class="f fdc aic">
            <img class="mb1" src="../../assets/complate.png" alt="" />
            <h2 class="mb2 align-c gray">
              Quý khách đã tạo mật khẩu<br />mới thành công!
            </h2>
            <button
              class="w100 h5 pt075 pb075 align-c border-radius bg-red-1 white"
            >
              Xác nhận mã OTP
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="hide">
      <svg
        width="9"
        height="10"
        viewBox="0 0 9 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="close"
          d="M5.78516 4.75L8.73828 1.82422C8.90234 1.66016 8.90234 1.35938 8.73828 1.19531L8.05469 0.511719C7.89062 0.347656 7.58984 0.347656 7.42578 0.511719L4.5 3.46484L1.54688 0.511719C1.38281 0.347656 1.08203 0.347656 0.917969 0.511719L0.234375 1.19531C0.0703125 1.35938 0.0703125 1.66016 0.234375 1.82422L3.1875 4.75L0.234375 7.70312C0.0703125 7.86719 0.0703125 8.16797 0.234375 8.33203L0.917969 9.01562C1.08203 9.17969 1.38281 9.17969 1.54688 9.01562L4.5 6.0625L7.42578 9.01562C7.58984 9.17969 7.89062 9.17969 8.05469 9.01562L8.73828 8.33203C8.90234 8.16797 8.90234 7.86719 8.73828 7.70312L5.78516 4.75Z"
          fill="#757575"
        />
      </svg>
      <svg  width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg" >
        <path
          id="back"
          d="M1.13672 4.53125L5.15625 0.484375C5.32031 0.320312 5.47526 0.320312 5.62109 0.484375L6.16797 1.00391C6.3138 1.16797 6.3138 1.32292 6.16797 1.46875L2.91406 4.75L6.16797 8.03125C6.3138 8.17708 6.3138 8.33203 6.16797 8.49609L5.62109 9.01562C5.47526 9.17969 5.32031 9.17969 5.15625 9.01562L1.13672 4.96875C0.990885 4.82292 0.990885 4.67708 1.13672 4.53125Z"
          fill="#2577C9"
        />
      </svg>
    </div>
    <NoticeView v-if="showNotiOTP">
        <div class="f fdc aic mb1">
        <div class="f fdc aic error-notice">
          <span class="error-icon"></span>
        </div>
        <h2 class="mt1 h2 red-1">Thông báo</h2>
      </div>
      <template>
        <div class="p6 gray align-c">Vui lòng nhập mã OTP đã gửi về địa chỉ email: <span class="black  h7">{{newEmail}}</span></div>
          <div class="code-confirm w100 f jcb aic mt05">
            <input type="number" v-model="num1" id="txt1" min=0 max=9  maxlength="1" v-on:keyup="move('','txt1','txt2')" />
            <input type="number" v-model="num2" id="txt2" min=0 max=9  maxlength="1" v-on:keyup="move('txt1','txt2','txt3')"/>
            <input type="number" v-model="num3" id="txt3" min=0 max=9  maxlength="1" v-on:keyup="move('txt2','txt3','txt4')"/>
            <input type="number" v-model="num4" id="txt4" min=0 max=9  maxlength="1" v-on:keyup="move('txt3','txt4','txt5')" />
            <input type="number" v-model="num5" id="txt5" min=0 max=9  maxlength="1" v-on:keyup="move('txt4','txt5','txt6')" />
            <input type="number" v-model="num6" id="txt6" min=0 max=9  maxlength="1" v-on:keyup="move('txt5','txt6','')" />
          </div>
          <div class="f jce w100 mt05">
            <p class="h7 blue">Gửi lại OTP</p> 
          </div>
      </template>
      <template v-slot:footer>
        <button @click="backOTP" class="button button--white notice__cta">
          Quay lại
        </button>
        <button @click="handelDoneNotiOTP" class="button button--primary notice__cta">
          Xác nhận
        </button>
      </template>
    </NoticeView>
    <NoticeView v-if="showDoneNotiOTP">
      <template>
        <div class="noti__img successful-noti mb1"></div>
        <h2 class="h2 red-1">Thông báo</h2>
        <p class="mt05 p6 gray mb2">Bạn đã đăng ký thành công</p>
        <button
          class="w100 h5 pt075 pb075 align-c border-radius bg-red-1 white"
          @click="loginNow"
        >
          Truy cập ngay
        </button>
        <p class="mt2 p6 gray mb2">
          Bạn sẽ truy cập vào hệ thống
          <span class="blue h5" id="timer">00:{{timeCountDown}}</span>
        </p>
      </template>
    </NoticeView>
  </div>
</template>

<script>
import axios from "axios";
// import DatePicker from "vuejs-datepicker";
import NoticeView from "@/components/NoticeView/NoticeView.vue";
import { HTTP } from '@/http-default';
const IS_ACTIVE = 'is-active'
export default {
  components: {
    // DatePicker,
    NoticeView,
  },
  data() {
    return {
      maxLength:1,
      city: [
        {
          type: "Xe tải",

          default: false,
        },
        {
          type: "Xe máy",

          default: true,
        },
        {
          type: "Xe ba gác",

          default: false,
        },
      ],
      // img
      fontSite: "",
      backSite: "",
      //noti
      showDoneNotiOTP:false,
      showNotiOTP: false,
      //
      btnFontUpload: true,
      btnBackUpload: true,
      //login
      username: "",
      password: "",
      email: "",
      passwordConfirm: "",
      type: "password",
      isChooseType: false,
      isChooseCityType: false,
      options: [],
      selectedOption: null,
      showFont: false,
      showBack: false,
      timeCountDown: 15,
      //register
      newPhone:"",
      newEmail:"",
      newPassword:"",
      againPassword:"",
      newIdAcc:"",
      //OTP
      num1:"",
      num2:"",
      num3:"",
      num4:"",
      num5:"",
      num6:"",
      //email forgot
      emailForgot:"",
      disableSubmit: true,
      enableSubmit: false,
      numFogot1:"",
      numFogot2:"",
      numFogot3:"",
      numFogot4:"",
      numFogot5:"",
      numFogot6:"",
      newForgotPassword:"",
      againForgotPassword:"",
      idStep:""
    };
  },
  watch:{
   numFogot6(e){
     if(e != ""){
       if(this.numFogot1,this.numFogot2,this.numFogot3,this.numFogot4,this.numFogot5 != "")
        this.disableSubmit= false,
        this.enableSubmit= true
     }
     else{
      this.disableSubmit= true,
      this.enableSubmit= false
     }
   }
  },
  methods: {
    //email quên mật khẩu
    sentEmailForgot(){
      const forgotEmail = JSON.stringify({
        "email" : this.emailForgot
      })
      var  url = 'https://logistic.nmeco.xyz/resetpassword/'
      const axiosConfig = {
        headers: { "Content-Type": "application/json"},
      };
      axios.post(url,forgotEmail,axiosConfig).then((res) =>{
        console.log(res,"fogotemail");
      })

    },
    //OTP đăng ký
    backOTP(){
      this.showNotiOTP = false,
      this.num1="",
      this.num2="",
      this.num3="",
      this.num4="",
      this.num5="",
      this.num6=""
    },
    move(e,p,c,n){
      var length = document.getElementById(p).value.length;
      var maxlength = document.getElementById(p).getAttribute("maxlength")
      if(length == maxlength){
        if(c !== ""){
          document.getElementById(c).focus();
        }
      }
      if(length == 0){
        document.getElementById(e).focus();
      }
    },
    // handleViewPassWord() {
    //   var iconOpen = document.getElementsByClassName("icon__input")[1];
    //   iconOpen.classList.toggle("active");
    //   var iconClose = document.getElementsByClassName("icon__input")[0];
    //   iconClose.classList.toggle("visible");
    //   var view = document.getElementsByClassName("visible");
    //   if (view.length > 0) {
    //     this.type = "text";
    //     this.$emit("typePassUpdate", this.type);
    //   } else {
    //     this.type = "password";
    //     this.$emit("typePassUpdate", this.type);
    //   }
    // },
    handleViewAccInfo(evt) {
      evt.preventDefault();
      var hide = document.querySelector(".person-info");
      hide.classList.add("hide");
      var visible = document.querySelector(".acc-info");
      visible.classList.remove("hide");
    },
    handleViewOTP(evt) {
      evt.preventDefault();
      var hide = document.querySelector(".acc-info");
      hide.classList.add("hide");
      var visible = document.querySelector(".acc-accuracy");
      visible.classList.remove("hide");
    },
    handleViewNotiOTP(evt) {
      evt.preventDefault();
      this.showNotiOTP = true;
      console.log(this.password == this.passwordConfirm,"duo");
      const axiosConfig = {
        headers: { "Content-Type": "application/json"},
      };
      if(this.password == this.passwordConfirm){
          var url = 'https://logistic.nmeco.xyz/register/'
          const newAcc = JSON.stringify({
          "username": this.newPhone,
          "email":this.newEmail,
          "password":this.newPassword,
        })
        axios.post(url,newAcc, axiosConfig) .then((res) =>{
          this.newIdAcc = res.data.id
          console.log(this.newIdAcc,"di");
        })
      }
      
    },
    handelDoneNotiOTP(evt){
      evt.preventDefault();
      this.showNotiOTP = false
      var otp = this.num1+this.num2+this.num3+this.num4+this.num5+this.num6
      var url = 'https://logistic.nmeco.xyz/activeuser/'
      const axiosConfig = {
        headers: { "Content-Type": "application/json"},
      };
      var postOtp = JSON.stringify({
        "code":otp
      })
      axios.post(url+this.newIdAcc,postOtp,axiosConfig).then((res) =>{
        if(res.status === 200){
           this.showDoneNotiOTP = true
            setInterval(() => {
            this.timeCountDown--
            if (this.timeCountDown === 0) {
              const newAcccount = JSON.stringify({
                username: this.newPhone,
                password: this.newPassword,
              })
              console.log(newAcccount,"duong");
              axios.post('https://logistic.nmeco.xyz/get-shipperid/',newAcccount,axiosConfig).then((res) =>{
                console.log(res,"duo2");
                  const user = res.data["token"];
                  const shipper = res.data["shipper_id"];
                  const userId = res.data["user_id"];
                  var getShipper = {
                    token: user,
                    shipper: shipper,
                  };
                  localStorage.setItem("shipper", JSON.stringify(shipper));
                  localStorage.setItem("user", JSON.stringify(getShipper));
                  localStorage.setItem("Token", user);
                  localStorage.setItem("userId",userId)
                  this.username = "";
                  this.password = "";
                  this.newPhone = "";
                  this.newEmail = "";
                  this.againPassword="";
                  this.newPassword = ""
                  this.$router.go({ path: "/home" });
              })
              clearInterval();
            }
          }, 1000);
        }
        else {
          alert("Mã OTP không chính xác")
        } 
      })
    },
    loginNow(){
      const newAcccount = JSON.stringify({
          username: this.newPhone,
          password: this.newPassword,
      })
      var url = 'https://logistic.nmeco.xyz/get-shipperid/'
        const axiosConfig = {
        headers: { "Content-Type": "application/json"},
      };
      axios.post(url,newAcccount,axiosConfig).then((res) =>{
       
        const user = res.data["token"];
        const shipper = res.data["shipper_id"];
        const userId = res.data["user_id"];
        var getShipper = {
          token: user,
          shipper: shipper,
        };
        localStorage.setItem("shipper", JSON.stringify(shipper));
        localStorage.setItem("user", JSON.stringify(getShipper));
        localStorage.setItem("Token", user);
        localStorage.setItem("userId",userId)
        this.username = "";
        this.password = "";
        this.newPhone = "";
        this.newEmail = "";
        this.againPassword="";
        this.newPassword = ""
        this.$router.go({ path: "/home" });
      })
    },
    closeOTP() {
      this.showDoneNotiOTP = false
    },
    chooseTypeCar() {
      this.isChooseType = !this.isChooseType;
    },
    chooseTypeCity() {
      this.isChooseCityType = !this.isChooseCityType;
    },
    handleClose() {
      document.querySelector(".auth").classList.remove("active");
      this.handleLogin();
      var forgot = document.querySelector(".forgot-password");
      forgot.classList.remove("active");
      this.handleBack();
    },
    handleRegister() {
      var login = document.querySelector(".login");
      login.classList.add("visible");
      var register = document.querySelector(".register");
      register.classList.add("active");
    },
    handleLogin() {
      var login = document.querySelector(".login");
      login.classList.remove("visible");
      var register = document.querySelector(".register");
      register.classList.remove("active");
    },
    handleForgot() {
      var login = document.querySelector(".login");
      login.classList.add("visible");
      var register = document.querySelector(".forgot-password");
      register.classList.add("active");
    },
    handleBack() {
      var login = document.querySelector(".login");
      login.classList.remove("visible");
      var register = document.querySelector(".forgot-password");
      register.classList.remove("active");
      var register1= document.querySelector(".register");
      register1.classList.remove("active");
      var hide = document.querySelector(".new-password");
      hide.classList.add("hide");
      var hide1 = document.querySelector(".complate");
      hide1.classList.add("hide");
      var visible = document.querySelector(".auth-info");
      console.log(visible);
      visible.classList.remove("hide");
      var circle1 = document.querySelectorAll(".step-circle")[1];
      circle1.classList.remove("step-control");
      var title1 = document.querySelectorAll(".step-title")[1];
      title1.classList.remove("brown");
      var circle2 = document.querySelectorAll(".step-circle")[2];
      circle2.classList.remove("step-control");
      var title2 = document.querySelectorAll(".step-title")[2];
      title2.classList.remove("brown");
      var circle = document.querySelectorAll(".step-circle")[0];
      circle.classList.add("step-control");
      var title = document.querySelectorAll(".step-title")[0];
      title.classList.add("brown");
    },
    handleToNewPassword(evt) {
      evt.preventDefault();
      var hide = document.querySelector(".auth-info");
      hide.classList.add("hide");
      var visible = document.querySelector(".new-password");
      visible.classList.remove("hide");
      var circle1 = document.querySelectorAll(".step-circle")[0];
      circle1.classList.remove("step-control");
      var title1 = document.querySelectorAll(".step-title")[0];
      title1.classList.remove("brown");
      var circle = document.querySelectorAll(".step-circle")[1];
      circle.classList.add("step-control");
      var title = document.querySelectorAll(".step-title")[1];
      title.classList.add("brown");
      var forgotOTP = this.numFogot1+this.numFogot2+this.numFogot3+this.numFogot4+this.numFogot5+this.numFogot6
      const dataForgot = JSON.stringify({
        "reset_code": forgotOTP,
        "email" : this.emailForgot,
        "step" : 1,
      })
       const axiosConfig = {
        headers: { "Content-Type": "application/json"},
      };
      var  url = 'https://logistic.nmeco.xyz/resetpassword/'
      axios.put(url,dataForgot,axiosConfig).then((res) =>{
        this.idStep = res.data.id
        this.emailForgot="",
        this.numFogot1="",
        this.numFogot2="",
        this.numFogot3="",
        this.numFogot4="",
        this.numFogot5="",
        this.numFogot6=""
      })
    },
    handleToComplate(evt) {
      evt.preventDefault();
      var hide = document.querySelector(".new-password");
      hide.classList.add("hide");
      var visible = document.querySelector(".complate");
      visible.classList.remove("hide");
      var circle1 = document.querySelectorAll(".step-circle")[1];
      circle1.classList.remove("step-control");
      var title1 = document.querySelectorAll(".step-title")[1];
      title1.classList.remove("brown");
      var circle = document.querySelectorAll(".step-circle")[2];
      circle.classList.add("step-control");
      var title = document.querySelectorAll(".step-title")[2];
      title.classList.add("brown");
        if(this.newForgotPassword === this.againForgotPassword){
            const step2 = JSON.stringify({
          "newpassword":this.newForgotPassword,
          "id":this.idStep,
          "step":2
        })
        const axiosConfig = {
          headers: { "Content-Type": "application/json"},
        };
        var url = 'https://logistic.nmeco.xyz/resetpassword/'
        axios.put(url,step2,axiosConfig).then((res) =>{
          console.log(res,"xacs nhan mk moi");
          this.newForgotPassword = "",
          this.againForgotPassword= ""
        })
      }
      else{
        alert('mật khẩu nhập lại không khớp')
      }
    },
    showFontPreview(event) {
      console.log("target");
      this.btnFontUpload = false;
      this.showFont = true;
      var src = URL.createObjectURL(event.target.files[0]);
      this.fontSite = src;
    },
    showBackPreview(event) {
      console.log("target2");
      this.btnBackUpload = false;
      this.showBack = true;
      var src = URL.createObjectURL(event.target.files[0]);
      this.backSite = src;
    },
    closeFont() {
      this.fontSite = "";
      this.btnFontUpload = true;
      this.showFont = false;
    },
    closeBack() {
      this.backSite = "";
      this.btnBackUpload = true;
      this.showBack = false;
    },
    seePassword(event) {
      const inputEl = event.target.parentElement.querySelector('input')
      inputEl.setAttribute('type', 'text')
      event.target.parentElement.classList.add(IS_ACTIVE)
    },
    hidePassword (event) {
      const inputEl = event.target.parentElement.querySelector('input')
      inputEl.setAttribute('type', 'password')
      event.target.parentElement.classList.remove(IS_ACTIVE)
    },
    login() {
      var dataUser = JSON.stringify({
        username: this.username,
        password: this.password,
      });
      const axiosConfig = {
        headers: { "Content-Type": "application/json" },
      };

      axios
        .post(
          "https://logistic.nmeco.xyz/get-shipperid/",
          dataUser,
          axiosConfig
        )
        .then((response) => {
           console.log(response,'dataLogin');
          const user = response.data["token"];
          const shipper = response.data["shipper_id"];
          const userId = response.data["user_id"];
          var getShipper = {
            token: user,
            shipper: shipper,
          };
          localStorage.setItem("shipper", JSON.stringify(shipper));
          localStorage.setItem("user", JSON.stringify(getShipper));
          localStorage.setItem("Token", user);
          localStorage.setItem("userId",userId)
          this.username = "";
          this.password = "";
          this.$router.go({ path: "/home" });
        })
        .catch(() => alert("Bạn đã nhập sai số điện thoại hoặc mật khẩu"));
    },
  },
  // mounted() {
  //   this.loadOptions();
  // },
};
</script>

<style scoped lang="scss">
@import "LoginView.scss";
</style>

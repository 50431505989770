<template>
  <div class="home h100vh bg-gray-1">
    <!-- <NullInfo></NullInfo> -->
    <div class="home__header mt25 pl1 pr1">
      <div class="h3">Đơn hàng</div>
      <div class="home__search relative w100">
        <div class="icon-search">
          <div class="icon icon--search"></div>
        </div>
        <input
          @change="searching"
          v-model="searchData"
          class="home__search-input w100 pt075 pb075 pr075"
          type="text"
          placeholder="Nhập mã đơn hàng bạn muốn tìm"
        />
      </div>
    </div>
    <OrderListView
      :ListOrder="ListOrder"
      :dataOrder="dataOrder"
      :finalOrdersList="finalOrdersList"
      :dataStatusOrder="dataStatusOrder"
    ></OrderListView>
    <NavBarView :dataNav="dataNav"></NavBarView>
  </div>
</template>

<script>
import OrderListView from "@/components/OrderView/OrderListView.vue";
// import NullInfo from "@/components/NullInformation/NullInfomation.vue"
import NavBarView from "@/components/NavBarView/NavBarView.vue";
import { HTTP } from "../http-default";
const endpoint = "/v1/api/order/";

export default {
  data() {
    return {
      dataSystem: [],
      dataNav: [],
      dataStatusOrder: [],
      dataOrder: [],
      ListOrder:[],
      finalOrdersList:[],
      latitude: null,
      longitude: null,

      // dataShipper: [],
      //search
      searchData:""
    };
  },
  components: {
    OrderListView,
    NavBarView,
    // NullInfo
  },
  methods : {
    //search
    searching(){
      HTTP.get('/v1/api/order/?search='+this.searchData).then((res) =>{
        console.log(res,'datasearch');
        this.ListOrder = res.data.results
      })
    },
    //getData order
     getDataOrder() {
      HTTP.get(endpoint).then((res) => {
        this.ListOrder = res.data.results;
        this.finalOrdersList = res.data.results;
        for (let i = 0; i < this.ListOrder.length; i++) {
          this.ListOrder[i].deposit_amount = this.ListOrder[
            i
          ].deposit_amount.toLocaleString("it-IT", {
            style: "currency",
            currency: "VND",
          });
          this.ListOrder[i].shipper_profit = this.ListOrder[
            i
          ].shipper_profit.toLocaleString("it-IT", {
            style: "currency",
            currency: "VND",
          });
          this.ListOrder[i].total_price = this.ListOrder[
            i
          ].total_price.toLocaleString("it-IT", {
            style: "currency",
            currency: "VND",
          });
        }
      });
    },
    getData() {
      HTTP.get('v1/api/system/')
      .then((res) => {
        const setting = res.data.results[0].router.toLowerCase()
        const results = setting.replaceAll("'", '"')
        this.dataSystem = JSON.parse(results)
        for (let index = 0; index < this.dataSystem.length; index++) {
          if (this.dataSystem[index].levelmenu == 1) {
            this.dataNav.push(this.dataSystem[index]);
          }
          if (this.dataSystem[index].path == this.$route.fullPath) {
            this.dataOrder.push(this.dataSystem[index]);
          }
          if (this.dataSystem[index].path == "/quan-ly-statusorder") {
            this.dataStatusOrder.push(this.dataSystem[index]);
          }
        }
      });
    },
    getLocation() {
      const success = (location) => {
        this.latitude = location.coords.latitude;
        this.longitude = location.coords.longitude;
        const stringLocation = ({
          "lat": this.latitude.toString(),
          "lng": this.longitude.toString(),
          "time": new Date().getTime(),
        });

        const shipLocation = JSON.stringify({
          "shipper": localStorage.getItem("shipper"),
          "location": stringLocation,
        });
        console.log(shipLocation);
        HTTP.post("v1/api/location/", shipLocation).then((res) => {
          console.log(res,"22");
        });
      };
      const error = (err) => {
        console.log(err);
      };
      
      navigator.geolocation.getCurrentPosition(success, error);
    },
    // callGetLocation() {
    //   setInterval(() => {
    //     this.getLocation();
    //     console.log("call");
    //   }, 3000);
    // },
  },

  created() {
    this.getData();
    this.getLocation();
    this.getDataOrder();
  },
  // mounted() {
  //   this.callGetLocation();
  // },
  watch: {
    latitude() {
      this.getLocation();
    },
    longitude() {
      this.getLocation();
    },
  },
};
</script>

<style scoped lang='scss'>
.home {
  width: 100vw;
}

.home__header {
  padding-top: 2.5em;
}

.home__search-input {
  padding-left: 2.25em;
}

.h3 {
  margin-bottom: 1em;
}

.icon-search {
  position: absolute;
  top: 28%;
  left: 0.5em;
}
</style>
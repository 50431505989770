<template>
  <div class="info__acc-wrap">
    <div class="info__acc border-radius bg-white-1 pt1 pr1 pb2 pl1 relative">
      <div class="info__acc-title h8 mb1 text-red-linear">
        Thông tin tài khoản
      </div>
      <div class="info__acc-log">
        <div class="info__acc-name">
          <div class="p4 mb05">Tên đăng nhập</div>
          <input
            class="pt075 pr075 pb075 pl075 p4 mb1 w100"
            type="text"
            :value="dataShiper.phone"
            disabled
          />
        </div>
        <div class="info__acc-pass">
          <div class="p4 mb05">Mật khẩu</div>
          <input
            class="pt075 pr075 pb075 pl075 p4 mb05 w100"
            value="*********"
            disabled
          />
        </div>
      </div>
      <div class="info__acc-change h7 f absolute" @click="changePass">
        Đổi mật khẩu
      </div>
    </div>
    <div v-if="isChangePass" class="acc__setChange fixed">
      <div class="acc__headerset border-radius pt1 pr1 pb15 pl1">
        <div class="f jcb mb1">
          <div class="text-red-linear h5">Đổi mật khẩu</div>
          <div class="h5 textcolor-1">Quên mật khẩu?</div>
        </div>
        <div class="mb15">
          <div class="mt1">
            <div class="p4 mb05">Mật khẩu hiện tại</div>
            <div class="form__password js-form-password">
              <input
                v-model="passwordNow"
                type="password"
                value="123456789"
                class="w100"
              />
              <span @click="seePassword($event)" class="icon icon--close-eye"/>
              <span @click="hidePassword($event)" class="icon icon--open-eye"/>
            </div>
          </div>
          <div class="mt1">
            <div class="p4 mb05">Mật khẩu mới</div>
            <div class="form__password js-form-password">
              <input
                v-model="newPassword"
                type="password"
                placeholder="Nhập mật khẩu mới"
                class="w100"
              />
              <span @click="seePassword($event)" class="icon icon--close-eye"/>
              <span @click="hidePassword($event)" class="icon icon--open-eye"/>
            </div>
          </div>
          <div class="mt1">
            <div class="p4 mb05">Nhập lại mật khẩu mới</div>
             <div class="form__password js-form-password">
              <input
                v-model="confirmPassword"
                type="password"
                placeholder="Nhập lại mật khẩu mới"
                class="w100"
              />
              <span @click="seePassword($event)" class="icon icon--close-eye"/>
              <span @click="hidePassword($event)" class="icon icon--open-eye"/>
            </div>
          </div>
        </div>
        <div class="grid grid--2 acc__footerset f jcb">
          <div class="grid__item">
            <button
              class="w100 button button--white h5 border-1 border-radius"
              @click="canclePass"
            >
              Hủy bỏ
            </button>
          </div>
          <div class="grid__item">
            <button
              class="w100 button button--primary h5 border-1 border-radius"
              @click="confirmAcc"
            >
              Xác nhận
            </button>
          </div>
        </div>
      </div>
    </div>
    <NoticeView v-if="isSuccess">
      <div class="noti__img successful-noti mb1"></div>
      <h2 class="h2 red-1">Thông báo</h2>
      <p class="p6 gray align-c">Đổi mật khẩu thành công</p>
      <template v-slot:footer>
        <button @click="close" class="button button--white notice__cta">
          Đóng
        </button>
      </template>
    </NoticeView>
      <NoticeView v-if="isError">
      <div class="mt2 mb2 error-notice">
        <span class="error-icon"></span>
      </div>
      <h2 class="h2 red-1">Thông báo</h2>
      <p class="p6 gray align-c">Đổi mật khẩu Không thành công</p>
      <template v-slot:footer>
        <button @click="close" class="button button--white notice__cta">
          Đóng
        </button>
      </template>
    </NoticeView>
  </div>
</template>

<script>
import NoticeView from "@/components/NoticeView/NoticeView.vue";
import { HTTP } from '@/http-default';
const IS_ACTIVE = 'is-active'
const endpointShipper = '/v1/api/shipper/'

export default {
  name: "InfoAccount",
  components: {
    NoticeView,
  },
  data() {
    return {
      isError:false,
      dataShiper:"",
      show: false,
      isChangePass: false,
      isSuccess: false,
      passwordNow: '',
      newPassword: '',
      confirmPassword: ''
    };
  },
  mounted(){
    this.getShiper()
  },
  methods: {
    getShiper(){
      HTTP.get(endpointShipper).then((res) =>{
        this.dataShiper = res.data.results[0]
      })
    },
    changePass() {
      this.show = true
      this.isChangePass = true
    },
    canclePass() {
      this.show = false
      this.isChangePass = false
      this.passwordNow = ""
      this.newPassword = ""
      this.confirmPassword = ""
    },
    confirmAcc() {
      if(this.newPassword === this.confirmPassword){
        this.isChangePass = false
        var url = 'https://logistic.nmeco.xyz/change-password/'
        const changePass = JSON.stringify({
          "oldpassword" : this.passwordNow,
          "newpassword": this.newPassword,
        })
        var idShip = this.dataShiper.user_shipper
        HTTP.post(url+idShip,changePass).then((res) =>{
        if(res.data.message === 'wrong oldpassword'){
            this.isError = true
            this.passwordNow = ""
            this.newPassword = ""
            this.confirmPassword = ""
          }
          else{
            this.isSuccess = true
            this.passwordNow = ""
            this.newPassword = ""
            this.confirmPassword = ""
          }
        })
      }
      else{
        alert('Mật khẩu mới không khớp')
      }
    },
    close() {
      this.isSuccess = false
      this.isError =false
    },
    seePassword(event) {
      const inputEl = event.target.parentElement.querySelector('input')
      inputEl.setAttribute('type', 'text')
      event.target.parentElement.classList.add(IS_ACTIVE)
    },
    hidePassword (event) {
      const inputEl = event.target.parentElement.querySelector('input')
      inputEl.setAttribute('type', 'password')
      event.target.parentElement.classList.remove(IS_ACTIVE)
    }
  }
};
</script>

<style scoped lang="scss">
@import "./InfoAcc.scss";
</style>

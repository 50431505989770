<template>
  <div class="your-card">
    <div
      class="your-card__header-wrap border-radius bg-white-1 pt1 pr1 pb1 pl1"
    >
      <div class="your-card__header f jcb">
        <div class="your-card__header-ur text-red-linear h5">
          Tài khoản ngân hàng
        </div>
        <div class="your-card__header-add f aic" @click="addCreditCard">
          <div class="mr075 h7 textcolor-1">Thêm</div>
          <div>
            <img src="../../../assets/Account/add-btn-2.svg" alt="card" />
          </div>
        </div>
      </div>
      <div class="your-card__container">
        <div class="your-card__container-icon f jcc mt1 mb1">
          <img src="../../../assets/Account/emoticon.svg" alt="card name" />
        </div>
        <div class="your-card__container-text h5 textcolor-2">
          Bạn chưa liên kết tài khoản nào để giao dịch trên BZIE
        </div>
        <div class="your-card__container-btn mb2">
          <button
            class="button button--primary h5 border-1 border-radius h5"
            @click="addCreditCard"
          >
            Thêm
          </button>
        </div>
      </div>
      <div class="your-card__header f fdc jcb">
        <div class="grid grid--2 mt075">
          <div class="grid__item p4 black">Tên ngân hàng</div>
          <div class="grid__item h7 black align-r">CP Bank</div>
        </div>
        <div class="grid grid--2 mt075">
          <div class="grid__item p4 black">Số tài khoản</div>
          <div class="grid__item h7 black align-r">798956497966</div>
        </div>
        <div class="grid grid--2 mt075">
          <div class="grid__item p4 black">Tên chủ tài khoản</div>
          <div class="grid__item h7 black align-r">Nguyen Dang Hoang Long</div>
        </div>
        <div class="grid grid--2 mt075">
          <div class="grid__item"></div>
          <div class="grid__item h7 black align-r f jce">
            <label class="mr05 p7">Mặc định</label>
            <div class="toggleWrapper">
              <input
                type="checkbox"
                name="toggle"
                class="mobileToggle"
                id="toggle"
                checked
              />
              <label for="toggle"></label>
            </div>
          </div>
        </div>
      </div>
      <div class="mt1 your-card__content">
        <div
          v-for="item in 3"
          class="f aic relative pt1 pb1 your-card__item"
          :key="item"
        >
          <div class="icon icon--logo-vcb your-card__logo" />
          <div class="ml1 your-card__info">
            <h5 class="h5 text-red-linear">Nguyễn Thị Bill</h5>
            <div class="f">
              <span class="p gray">Đặt làm mặc định</span>
              <div class="ml075 toggleWrapper">
                <input
                  type="checkbox"
                  :name="`toggle-card-${item}`"
                  class="mobileToggle"
                  :id="`toggle-card-${item}`"
                  checked
                />
                <label :for="`toggle-card-${item}`"></label>
              </div>
            </div>
          </div>
          <button @click="remove" class="icon icon--delete your-card__remove"/>
        </div>
      </div>
    </div>
    <!-- one -->
    <div v-if="isAddCreditCard" class="acc__setChange fixed">
      <div class="acc__headerset border-radius pt1 pr1 pb15 pl1">
        <div class="text-red-linear h5 mb1">Thêm tài khoản ngân hàng</div>
        <div>
          <div class="relative your-card__bank">
            <div class="p4 mb05">Tên ngân hàng</div>
            <div
              @click="chooseBank"
              class="your-card__typeBox f jcb h6 border pt075 pr075 pb075 pl075 border-r4 mb1"
            >
              <div ref="bankName">Chọn ngân hàng</div>
              <div class="f">
                <div class="mr05">|</div>
                <div class="icon icon--back icon--back-two"></div>
              </div>
            </div>
            <label v-if="isErrorChooseBank" class="p7 message message--error">Cần chọn ngân hàng</label>
          <div
            v-if="isChooseBank"
            class="your-card__typeWrap"
          >
            <div
              class="
                your-card__typeItem
                border border-r4
                w100
                pr075
                pl075
              "
            >
              <p
                v-for="(item, key) in listBanks"
                :key="key"
                @click="getBank(item)"
                class="pb075 pt075 h7 border-2"
              >
                {{ item.name }}
              </p>
            </div>
          </div>
          </div>
          <div class="inputCreNumber">
            <div class="p4 mb05">Nhập số tài khoản</div>
            <input
              type="text"
              placeholder="Nhập số tài khoản"
              class="pt075 pr075 pb075 pl075 w100 mb1 p4"
              v-model="accountCard"
              ref="accountCard"
            />
            <label v-if="isErrorAccountCard" class="p7 message message--error">Cần nhập số tài khoản</label>
          </div>
          <div>
            <div class="p4 mb05">Nhập tên chủ tài khoản</div>
            <input
              type="text"
              placeholder="Nhập tên chủ tài khoản"
              class="inputName pt075 pr075 pb075 pl075 w100 mb1 p4 border-r4"
              v-model="accountName"
              ref="accountName"
            />
            <label v-if="isErrorAccountName" class="p7 message message--error">Cần nhập tên tài khoản</label>
          </div>
          <div class="grid grid--2">
            <div class="grid__item">
              <button
                class="button button--white w100 h5 border-radius black pt1 pb1"
                @click="cancelAddCreditCard"
              >
                Hủy bỏ
              </button>
            </div>
            <div class="grid__item">
              <button
                class="button button--primary w100 h5 border-radius white"
                :class="{'c-red-faild': !isSuccessAddCard}"
                @click="confirmInfo"
              >
                Tiếp tục
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- two -->
    <div v-if="isConfirmInfo" class="acc__confirmInfo fixed">
      <div class="acc__secondset border-radius pt1 pr1 pb15 pl1">
        <div class="text-red-linear h5 mb1">Xác nhận thông tin</div>
        <div class="mb1">
          <div class="p4 mb05">Tên ngân hàng</div>
          <input
            type="text"
            v-model="bankName"
            class="h6 pt075 pr075 pb075 pl075 w100 c-gray-back"
            disabled
          />
        </div>
        <div class="mb1">
          <div class="p4 mb05">Số tài khoản</div>
          <input
            type="text"
            v-model="accountCard"
            class="h6 pt075 pr075 pb075 pl075 w100 c-gray-back"
            disabled
          />
        </div>
        <div class="mb1">
          <div class="p4 mb05">Tên chủ tài khoản</div>
          <input
            type="text"
            v-model="accountName"
            class="h6 pt075 pr075 pb075 pl075 w100 c-gray-back"
            disabled
          />
        </div>
        <p class="mt1 p text-red-linear align-c">
          Vui lòng kiểm tra lại thông tin và xác nhận
        </p>

        <!-- the end button -->
        <div class="grid grid--2 mt15">
          <div class="grid__item">
            <button
              class="w100 button button--white"
              @click="cancelConfirmInfo"
            >
              Hủy bỏ
            </button>
          </div>
          <div class="grid__item">
            <button class="w100 button button--primary" @click="confirm">
              Xác nhận
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- thông báo cập nhật thông tin thành công -->
    <NoticeView v-if="isSuccess">
      <template v-slot:header>
        <h2 class="h2 red-1">Thông báo</h2>
      </template>
      <p class="p6 gray">Cập nhật thông tin cá nhân thành công!</p>
      <template v-slot:footer>
        <button @click="close" class="button button--white notice__cta">
          Đóng
        </button>
      </template>
    </NoticeView>

    <!-- delete account bank -->
    <NoticeView v-if="isDeleteAccount">
      <template v-slot:header>
        <h2 class="h2 red-1">Thông báo</h2>
      </template>
      <p class="p6 gray">Bạn chắc chắn muốn xóa tài khoản?</p>
      <template v-slot:footer>
        <button @click="handleBack" class="button button--white notice__cta">
          Quay lại
        </button>
        <button @click="deleteAccount" class="button button--primary notice__cta">
          Xác nhận
        </button>
      </template>
    </NoticeView>
  </div>
</template>

<script>
import NoticeView from "@/components/NoticeView/NoticeView.vue";
export default {
  components: {
    NoticeView
  },
  data() {
    return {
      months: [
        { type: "Tháng 1" },
        { type: "Tháng 2" },
        { type: "Tháng 3" },
        { type: "Tháng 4" },
        { type: "Tháng 5" },
        { type: "Tháng 6" },
        { type: "Tháng 7" },
        { type: "Tháng 8" },
        { type: "Tháng 9" },
        { type: "Tháng 10" },
        { type: "Tháng 11" },
        { type: "Tháng 12" },
      ],
      isAddCreditCard: false,
      isConfirmInfo: false,
      isSuccess: false,
      isDeleteAccount: false,
      listBanks: [
          {
              name: 'Techcombank',
              value: 'tcb'
          },
          {
              name: 'Vietcombank',
              value: 'vcb'
          },
          {
              name: 'MB Bank',
              value: 'mb'
          }
      ],
      bankName: null,
      isChooseBank: false,
      accountCard: null,
      accountName: null,
      isErrorAccountCard: false,
      isErrorAccountName: false,
      isErrorChooseBank: false,
      isSuccessAddCard: false
    };
  },
  methods: {
    addCreditCard() {
      this.isAddCreditCard = true;
    },
    cancelAddCreditCard() {
      this.isAddCreditCard = false;
    },
    chooseBank() {
      this.isChooseBank = true
    },
    getBank(item) {
      this.isChooseBank = false
      this.$refs.bankName.innerHTML = item.name
      this.bankName = item.name
      if(this.bankName) this.isErrorChooseBank = false
    },
    confirmInfo() {
      if(!this.bankName) {
        this.isErrorChooseBank = true
        return false
      }
      if(!this.accountCard) {
        this.isErrorAccountCard = true
        this.$refs.accountCard.focus()
        return false
      }
      if(!this.accountName) {
        this.isErrorAccountCard = false
        this.isErrorAccountName = true
        this.$refs.accountName.focus()
        return false
      }
      this.isConfirmInfo = true
    },
    cancelConfirmInfo() {
      this.isConfirmInfo = false;
      this.isAddCreditCard = false;
    },
    confirm() {
      this.bankName = ''
      this.accountCard = ''
      this.accountName = ''
      this.isAddCreditCard = false;
      this.isConfirmInfo = false;
      this.isSuccess = true;
    },
    close() {
      this.isSuccess = false;
    },
    handleBack () {
      this.$router.go(-1)
    },
    remove () {
      this.isDeleteAccount = true
    },
    deleteAccount() {
      this.isDeleteAccount = false
    }
  },
};
</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import "YourCard.scss";
</style>

<template>
    <div class="account bg-gray-1 relative pt15 pr1 pb15 pl1">
        <InfoAccount></InfoAccount>
        <PersonAcc :vehicles-type="vehiclesType"></PersonAcc>
        <YourCard></YourCard>
        <NavBarView/>
    </div>
</template>

<script>
import InfoAccount from '@/components/AccountInfo/InfoAcc/InfoAccount.vue'
import PersonAcc from '@/components/AccountInfo/InfoPerson/PersonAcc.vue'
import YourCard from '@/components/AccountInfo/YourCard/YourCard.vue'
import NavBarView from '@/components/NavBarView/NavBarView.vue'
import { HTTP } from '@/http-default'
const endpointVehicleType = '/v1/api/vehicletype/'

export default {
    name: 'MyAccount',
    components: {
        InfoAccount,
        PersonAcc,
        YourCard,
        NavBarView
    },
    data() {
        return {
            vehiclesType: []
        }
    },
    created () {
        this.getVehiclesType()
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        async getVehiclesType() {
            const response = await HTTP.get(endpointVehicleType)
            return this.vehiclesType = response.data.results
        }
    }
}
</script>
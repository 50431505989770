<template>
    <div class="h100vh bg-gray-1 detailed-operating">
        <div class="relative detailed-operating__header">
            <div
                @click="handleBack"
                class="detailed-operating__header w100 f jcb aic pt075 pb075 pl1 pr1 bg-white-1"
            >
                <div class="f aic">
                    <div class="detailed-operating__header-back icon icon--back"></div>
                    <div class="h5">Chi tiết vận đơn</div>
                </div>
            </div>
            <div class="detailed-operating__body pl1 pr1">
                <div class="pb1 detailed-operating__title h3">Trạng thái đơn hàng</div>
                <div class="timeline">
                    <div class="timeline_wrapper">
                        <div
                            v-for="(item, index) in listTimeline"
                            :key="index"
                            class="timeline__item"
                        >
                            <div class="timeline__time">
                                <p class="p7 gray timeline__time-date">{{`${getDate(item.updated_at)}/${getMonth(item.updated_at)}`}}</p>
                                <p class="p7 gray mt05 timeline__time-hours">{{`${getHours(item.updated_at)}:${getMinutes(item.updated_at)}`}}</p>
                            </div>
                            <div class="timeline__content">
                                <ul>
                                    <li class="h6 gray">{{item.title_logorder}}</li>
                                    <li class="h6 gray">{{item.note}}</li>
                                    <li> 
                                        <div v-if="item.image" class="mediaLog"  >
                                            <img :src="item.image">
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/http-default'

export default {
    data() {
        return {
            listTimeline: [],
            filterTimeline: [],
            abc: true
        }
    },
    // watch:{
    //     image(e){
    //         console.log(e,"asdas");
    //     }
    // },
    computed: {
        getOrderId() {
            return Number(this.$route.params.id)
        }
    },
    methods: {
        getData() {
            HTTP.get('/v1/api/logorder/')
            .then(res => {
                var dataImg = res.data.results
                this.listTimeline = dataImg.filter(item => item.order == this.getOrderId)
            })
        },
        handleBack() {
            this.$router.go(-1)
        },
        convertDate(time) {
            this.date = new Date(time)
        },
        getDate(time) {
            this.convertDate(time)
            return this.date.getDate()
        },
        getMonth(time) {
            this.convertDate(time)
            return this.date.getMonth() + 1
        },
        getHours(time) {
            this.convertDate(time)
            return this.date.getHours()
        },
        getMinutes(time) {
            this.convertDate(time)
            return this.date.getMinutes()
        }
    },
    async created() {
        this.getData()
    }
}
</script>

<style scoped lang="scss">
    @import "OrderStatus.scss";
</style>
<template>
  <div class="notiview h100vh bg-gray-1">
    <div class="notiview__header mt25 pl1 pr1 mb2">
      <div class="h3">Thông báo</div>
      <div class="notiview__search relative w100">
        <div class="icon-search">
          <div class="icon icon--search"></div>
        </div>
        <input
          class="notiview__search-input w100 pt075 pb075 px1"
          type="text"
          placeholder="Giúp bạn tìm kiếm thông báo dễ dàng"
        />
      </div>
    </div>
    <div class="noti-list__header w100 f jcb aic pt075 pb075 pl1 pr1 bg-white-1"
      v-for="(item, index) in dataNoti" :key="index">
      <div @click="goOrder(item)">
          <div class="f aic">
            <div class="icon-shop bg-green-1 f jcc aic mr2">
              <div class="shopping"></div>
            </div>
            <div class="f fw jcs">
              <div class="h7 black mr2 mb1">Thông báo đơn hàng</div>
              <div class="h7 blue btnAccept">Xác nhận!</div>
              <div class="f fdc">
                <div>Bạn vừa nhận một đơn hàng mới</div>
                <div class="mt0.5">Mã #{{ item }}</div>
              </div>
               <div class="h6 gray mb1">Cập nhật từ BZI | 22/02/2022 09:00AM</div>
            </div>
          </div>
      </div>
    </div>
    <NavBarView :dataNav="dataNav"></NavBarView>
  </div>
</template>

<script>
import firebase from "@/firebaseinit";
import "firebase/firestore";
import NavBarView from "@/components/NavBarView/NavBarView.vue";
import { HTTP } from '@/http-default'
export default {
  data() {
    return {
      dataNav: [],
      dataNoti: [],
    };
  },
  components: {
    NavBarView,
  },
  methods: {
    goOrder(item){
      var url = '/v1/api/order/?search='
      HTTP.get(url+item).then((res) =>{
        var ordeKey = res.data.results[0].id
       this.$router.push({path: "/quan-ly-order/"+ordeKey})
      })
    },
    async getNoti() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let path = this;
      var ref = firebase.database().ref("/order_logistic/");
        console.log(ref)
      ref.on("child_added", function (childSnapshot) {
        path.dataNoti.unshift(childSnapshot.val());
      });
    },
  },
  created() {
    this.getNoti();
  },
};
</script>

<style  scoped lang="scss">
@import "NotiMain.scss";
</style>
<template>
  <div id="app" class="h100vh">
        <!-- <NullInfo></NullInfo> -->
    <router-view/>
  </div>
</template>

<script>
// import NullInfo from "@/components/NullInformation/NullInfomation.vue"
export default {
  data(){
    return{

    }
  },
   components: {
    // NullInfo
  },
}

</script>


<style lang="scss"> 
@import '/global/index';
</style>

<template>
    <div class="cart-in-popup jcc aic">
        <div class="cart-in-container border-radius bg-white-1">
            <div class="container-header h8 mb025 text-red-linear">Chọn khoản tiền nạp</div>
            <div class="container-select">
                <div class="container-select__title h6 mb1 gray">Vui lòng chọn khoản tiền nạp</div>
                <div class="container-select__list f jcb aic fw">
                    <div 
                        class="container-select__list-item relative h8 mb1 pt1 pb1 align-c border"
                        v-for="(item, index) in selectList"
                        :key="index"
                        @click="handleSelect(item, $event)">
                        
                        <img class="mb1" src="../../../assets/vietcombank.png" alt="image">
                        <p>{{ item.money }}</p>
                        <div class="select-logo"></div>
                    </div>
                </div>
            </div>
            <div class="container-custom mb1">
                <div @click="handleShowTopupCard" class="container-custom__checkbox f aic mb075">
                    <input v-model="enableTopUpCard" type="checkbox">
                    <div class="p4 ml075">Nhập khoản tiền nạp</div>
                </div>
                <div v-if="isShowTopUpCard" class="container-custom__input">
                    <input v-model="topUpCard" class="w100 h6" type="number" placeholder="Nhập số tiền cần nạp" :disabled="!enableTopUpCard" @input="handleInputTopupCard">
                </div>
            </div>
            <div class="container-total f jcb aic mb15">
                <div class="h8 gray">Tổng tiền nạp</div>
                <div class="h8 text-red-linear">{{finalTopupCard}}</div>
            </div>
            <div class="container-btn f jcb aic h8">
                <div 
                    class="w50 pt1 pb1 align-c border-radius border"
                    @click="handleBack"
                >
                    Quay lại
                </div>
                <div @click="progressPayment" class="w50 pt1 pb1 ml1 align-c border-radius bg-red-1 white">Tiếp tục</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            selectList : [
                { money : "50.000" },
                { money : "100.000" },
                { money : "200.000" },
                { money : "500.000" },
                { money : "1.000.000" },
                { money : "2.000.000" },
            ],
            enableTopUpCard: false,
            topUpCard: '',
            finalTopupCard: 0,
            isShowTopUpCard: false
        }
    },
    watch: {
        topUpCard (newValue) {
            if (newValue) this.formatActive()
        }
    },
    methods: {
        formatActive () {
            const activePrices = Array.from(document.querySelectorAll('.select-logo'))
            activePrices.map(item => item.innerHTML = '')
        },
        handleSelect(data, event) {
            const formatPrice = data.money.split('.').join('')
            this.finalTopupCard = Number(formatPrice).toLocaleString('it-IT', {style : 'currency', currency : 'VND'})
            this.enableTopUpCard = false
            this.isShowTopUpCard = false
            this.topUpCard = ''
            var button = event.target.parentElement
            this.formatActive()
            var select = button.getElementsByClassName('select-logo')[0]
            select.innerHTML = `
                <div class="absolute" style="top: 0.5em; right: 0.5em">
                    <div class="icon icon--check-circle"></div>
                </div>
            `
        },
        handleBack() {
            this.$emit('close', false)
        },
        progressPayment() {
            this.$emit('continue', true)
        },
        handleInputTopupCard () {
            this.finalTopupCard = Number(this.topUpCard).toLocaleString('it-IT', {style : 'currency', currency : 'VND'})
        },
        handleShowTopupCard() {
            this.enableTopUpCard = !this.enableTopUpCard
            if (this.enableTopUpCard) this.isShowTopUpCard = true
            else this.isShowTopUpCard = false
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "CartInPopup.scss";
</style>>

<template>
  <div class="cart relative bg-gray-1">
    <div
      class="cart__header w100 f jcb aic pt075 pb075 pl1 pr1 bg-white-1"
      @click="handleBack"
    >
      <div class="f aic">
        <div class="cart__header-back icon icon--back"></div>
        <div class="h5">Quay lại</div>
      </div>
    </div>
    <div class="cart__body pt2 pr1 pl1">
      <div class="cart__body-title f aic jcb mb15">
        <div class="h3">Ví BZIE</div>
        <div
          @click="handlePayment"
          class="cart__body-btn f aic border-radius bg-red-1"
        >
          <div class="icon icon--cart-white"></div>
          <div class="h8 ml05 white">Nạp tiền</div>
        </div>
      </div>
      <div class="cart__body-search relative w100 mb15">
        <div class="icon-search">
          <div class="icon icon--search"></div>
        </div>
        <input
          class="body__search-input w100 pt075 pb075 pr075"
          type="text"
          placeholder="Tìm kiếm"
        />
      </div>
      <div class="cart__body-filter f aic mb15">
        <div
          v-for="(item, index) in filter"
          :key="index"
          class="filter__item filter-select pt025 pb025 pl05 pr05 border-radius mr05 bg-red-2"
        >
          <div class="filter-select-text h7">{{ item.name }}</div>
        </div>
      </div>
      <div
        v-if="wallet"
        class="cart__body-BZI pt1 pb1 pl1 pr1 border-radius bg-white-1"
      >
        <div class="BZI-cart__title h8 mb1">Ví BZIE</div>

        <div class="BZI-cart__container relative f fdc jcb mb1">
          <div class="container__header f jcb aic">
            <div class="">
              <div class="h8 white">Ví BZIE</div>
            </div>
            <div
              @click="handlePayment"
              class="container__header-btn f aic border-radius border bg-white-1"
            >
              <div class="icon icon--cart-red"></div>
              <div class="h7 ml05 text-red-linear">Nạp tiền</div>
            </div>
          </div>
          <div class="container__body">
            <div class="white p-bold">
              {{
                Number(wallet.wallet_balance).toLocaleString("it-IT", {
                  style: "currency",
                  currency: "VND",
                })
              }}
            </div>
            <div class="white p-small mt025">Số dư trong ví</div>
          </div>
          <div class="f aic jcb">
            <div class="white">
              <div class="p-small mb05">Tên chủ tài khoản</div>
              <div class="p-xsmall">{{ fullName }}</div>
            </div>
            <div class="white">
              <div class="p-small mb05">Trạng thái</div>
              <div class="p-xsmall">Đã liên kết tài khoản</div>
            </div>
          </div>
          <div class="cart-BZI absolute"></div>
        </div>

        <div class="BZI-cart__bank f pt1 border-top">
          <div class="bank__img mr1">
            <img src="../../assets/vietcombank.png" alt="" />
          </div>
          <div class="bank__info fdc">
            <div class="mb025 h8">Nguyễn Thị Bill</div>
            <div class="mb025 h6 gray">123 456 789 123</div>
            <div class="mb1 p-navsmall text-red-linear">
              Tài khoản đang liên kết
            </div>
            <div class="bank__info-btn f aic border border-radius">
              <div class="icon icon--cart-red"></div>
              <div class="text-red-linear h7 ml05" @click="handleWithdrawals">
                Rút tiền
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="exchangeData && finalExchangeData && finalExchangeData.length"
        class="cart__body-noti"
      >
        <div v-for="(item, index) in exchangeData" :key="index">
          <div class="noti__item border-radius mt1 bg-white-1">
            <div
              @click="handleModalWallet"
              class="noti__item-header f aic jcb mb1"
            >
              <div class="item__header-left f aic">
                <div class="profit"></div>
                <div class="f fdc jcb ml075">
                  <div class="h7 blue">Lợi nhuận BZIE</div>
                  <div class="noti__item-id h6 gray">
                    #{{ item.exchange_key }}
                  </div>
                </div>
              </div>
              <div
                v-if="item.status_exchange === 2"
                class="item__header-right h7 pt025 pb025 pl05 pr05 border-radius green bg-green"
              >
                {{ getStatusExchangeName(item) }}
              </div>
              <div
                v-if="item.status_exchange === 3"
                class="item__header-right h7 pt025 pb025 pl05 pr05 border-radius red-2-text bg-red-faild"
              >
                {{ getStatusExchangeName(item) }}
              </div>
              <div
                v-if="item.status_exchange === 4"
                class="item__header-right h7 pt025 pb025 pl05 pr05 border-radius orange bg-yellow"
              >
                {{ getStatusExchangeName(item) }}
              </div>
            </div>
            <div class="noti__item-body">
              <div class="f jcb aic mb05">
                <div class="h6 gray">Ngày giao dịch</div>
                <div class="h6">
                  {{
                    `${getDate(item.created_at)}/${getMonth(
                      item.created_at
                    )}/${getYear(item.created_at)}`
                  }}
                </div>
              </div>
              <div class="f jcb aic">
                <div class="h6 gray">Tổng giao dịch</div>
                <div class="h6">135.000VND</div>
              </div>
            </div>
          </div>
          <NotiCartPopup
            v-if="isShowModal"
            :order-id="item.order"
            :exchange-data="exchangeData[index]"
            state-wallet="Lợi nhuận BZIE"
            @close="handleClose"
          ></NotiCartPopup>
        </div>
      </div>
    </div>
    <CartOutPopupView v-if="handleCartOutPopupView" @cancel="cancel" />
    <CartInPopupView
      v-if="isPayment"
      @close="closeCartInPopupView"
      @continue="progressPayment"
    />
    <ProgressPayment v-if="isProgressPayment" @payment-done="paymentDone" />
    <PaymentDone v-if="isPaymentDone" @close-payment-done="closePayment" />
    <NavBarView></NavBarView>
  </div>
</template>

<script>
import NavBarView from "@/components/NavBarView/NavBarView.vue";
import NotiCartPopup from "@/components/CartBZI/NotiCartPopupView/NotiCartPopupView.vue";
import CartOutPopupView from "@/components/CartBZI/CartOutPopupView/CartOutPopupView.vue";
import CartInPopupView from "@/components/CartBZI/CartInPopupView/CartInPopupview";
import ProgressPayment from "@/components/CartBZI/ProgressPayment/ProgressPayment.vue";
import PaymentDone from "@/components/CartBZI/PaymentDone/PaymentDone.vue";
import { HTTP } from "@/http-default";
const endpointWallet = "v1/api/wallet/";
const endpointShipper = "v1/api/shipper/";
const endpointExchange = "v1/api/exchange/";
const endpointStatusExchange = "v1/api/status-exchange/";
const shipperId = localStorage.getItem("shipper");

export default {
  data() {
    return {
      filter: [
        { name: "Chờ nhận" },
        { name: "Đang xử lý" },
        { name: "Thành công" },
        { name: "Thất bại" },
      ],
      handleCartOutPopupView: false,
      isPayment: false,
      isProgressPayment: false,
      isPaymentDone: false,
      wallet: null,
      fullName: null,
      exchangeData: null,
      finalExchangeData: null,
      orderId: null,
      isShowModal: false,
    };
  },
  components: {
    NavBarView,
    NotiCartPopup,
    CartOutPopupView,
    CartInPopupView,
    ProgressPayment,
    PaymentDone,
  },
  async created() {
    this.getWallet();
    this.getUser();
    this.getExchange();
    await this.getStatusExchange();
  },
  methods: {
    async getUser() {
      const response = await HTTP.get(endpointShipper);
      this.userData = response.data.results[0];
      this.fullName = this.userData.full_name;
    },
    async getWallet() {
      const response = await HTTP.get(endpointWallet);
      this.wallet = response.data.results.filter(
        (item) => item.shipper == shipperId
      )[0];
    },
    async getExchange() {
      const response = await HTTP.get(endpointExchange);
      this.exchangeData = response.data.results;
    },
    async getStatusExchange() {
      const response = await HTTP.get(endpointStatusExchange);
      this.finalExchangeData = JSON.parse(
        JSON.stringify(response.data.results)
      );
    },
    getStatusExchangeName(statusExchange) {
      return JSON.parse(JSON.stringify(this.finalExchangeData)).filter(
        (item) => item.id == statusExchange.status_exchange
      )[0].title_status_exchange;
    },
    convertDate(time) {
      this.date = new Date(time);
    },
    getDate(time) {
      this.convertDate(time);
      return this.date.getDate();
    },
    getMonth(time) {
      this.convertDate(time);
      return this.date.getMonth() + 1;
    },
    getYear(time) {
      this.convertDate(time);
      return this.date.getFullYear();
    },
    handleModalWallet() {
      this.isShowModal = true;
    },
    handleBack() {
      this.$router.go(-1);
    },
    handleWithdrawals() {
      this.handleCartOutPopupView = true;
    },
    handlePayment() {
      this.isPayment = true;
    },
    closeCartInPopupView(payload) {
      this.isPayment = payload;
    },
    progressPayment() {
      this.isPayment = false;
      this.isProgressPayment = !this.isProgressPayment;
    },
    paymentDone(payload) {
      this.isProgressPayment = !this.isProgressPayment;
      this.isPaymentDone = payload;
    },
    closePayment(payload) {
      this.isPaymentDone = payload;
    },
    cancel(payload) {
      this.handleCartOutPopupView = payload;
    },
    handleClose(value) {
      this.isShowModal = value;
    },
  },
};
</script>

<style scoped lang="scss">
@import "CartBZI.scss";
</style>

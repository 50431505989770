<template>
  <div class="delivery-fail bg-gray-1 h100vh">
    <div
      class=" delivery-fail__header w100 f jcb aic pt075 pb075 pl1 pr1 bg-white-1">
      <div class="f aic" @click="handleBack">
        <div class="delivery-fail__header-back icon icon--back"></div>
        <div class="h5">Quay lại</div>
      </div>
      <div class="delivery-fail__header-call f aic">
        <a class="h8 blue mr05" :href="`tel:${[dataOrder.customer.data.phone]}`">Gọi khách</a>
        <div class="icon icon--phone"></div>
      </div>
    </div>

    <div class="delivery-fail__body pt15 pl1 pr1">
      <div class="body__title f fdc jcc aic mb15">
        <div class="body__title-code p6 mb05">Đơn hàng #{{ dataOrder.id }}</div>
        <h3 class="body__title-status h2 green">Giao thành công</h3>
      </div>
      <!-- Tải ảnh -->
      <div class="body__media f jcc aic mb1 border-radius bg-gray-5">
        <div class="body__media-title f" v-if="btnSuccessUpload">
          <label
            for="successful"
            class="h5 mr1 pt05 pb05 pl1 pr1 border-radius bg-blue white"
          >
            Tải ảnh lên
          </label>
          <div class="f fdc jcb">
            <div class="h7">Thêm ảnh</div>
            <div class="h6 gray">Dung lượng tối đa 2MB</div>
          </div>
        </div>

        <div class="succsesspreview f jcc aic">
          <input type="file" id="successful" ref="uploadBtn"  @change="showImgSuccess" />
          <div class="succsesspreview__show f jce relative" v-if="showSuccess">
            <div class="absolute icon close-img" @click="closeSuccess"></div>
            <img :src="urlSuccess" />
          </div>
        </div>
      </div>
      <div class="body__note mb15">
        <div class="body__note-title p4">Chú thích</div>
        <textarea
          class="h7 pt05 pb05 pl075 pr075"
          name=""
          id=""
          cols="30"
          rows="10"
          placeholder="Thêm ghi chú"
          v-model="note"
        ></textarea>
      </div>
    </div>

    <div class="delivery-fail__btn w100 f jcb aic bg-white-1">
      <div class="w50 h8 pt1 pb1 border align-c border-radius">Huỷ bỏ</div>
      <div
        v-if="enableSubmit"
        @click="handleSubmit"
        class="w50 h8 pt1 pb1 ml05 align-c border-radius bg-red-1 white"
      >
        Gửi
      </div>
      <fieldset
        class="w50 h8 pt1 pb1 ml05 align-c border-radius c-red-faild white"
        v-if="disableSubmit"
        disabled
      >
        <div>Gửi</div>
      </fieldset>
    </div>

    <NoticeView v-if="isShowModal">
      <template>
        <div class="noti__img successful-noti mb1"></div>
        <h2 class="h2 red-1">Thông báo</h2>
      </template>
      <template>
        <div class="p6 gray">
          Đơn hàng đã hoàn thành. Lợi nhuận của bạn sẽ được chuyển đến ví theo
          <a href="#" class="h7 blue">Điều khoản và Điều kiện</a> của chúng tôi.
        </div>
      </template>
      <template v-slot:footer>
        <button @click="handleBack" class="button button--white notice__cta">
          Quay lại
        </button>
        <button
          @click="handleConfirmSuccess"
          class="button button--primary notice__cta"
        >
          Xác nhận
        </button>
      </template>
    </NoticeView>
  </div>
</template>

<script>
import { HTTP } from "../../http-default";
import NoticeView from "@/components/NoticeView/NoticeView.vue";

export default {
  components: {
    NoticeView,
  },
  data() {
    return {
      dataOrder: [],
      isShowModal: false,
      btnSuccessUpload: true,
      showSuccess: false,
      disableSubmit: true,
      enableSubmit: false,
      note:"",
      fileUpload:"",
      urlSuccess:""
    };
  },
  methods: {
    showImgSuccess(e) {
      let files = this.$refs.uploadBtn.files
      this.fileUpload = files
      this.btnSuccessUpload = false;
      this.showSuccess = true;
      var src = URL.createObjectURL(e.target.files[0]);
      this.urlSuccess = src;
      if (e.target.files.length > 0) {
        this.enableSubmit = true;
        this.disableSubmit = false;
      }
    },
    closeSuccess() {
      this.urlSuccess = "";
      this.btnSuccessUpload = true;
      this.showSuccess = false;
      this.enableSubmit = false;
      this.disableSubmit = true;
    },
    getOrder() {
      return this.$route.params.id;
    },
    getData() {
      HTTP.get(`/v1/api/order/${this.getOrder()}/`).then(
        (res) => (this.dataOrder = res.data)
      );
    },
    handleBack() {
      this.$router.go(-1);
    },
    handleSubmit() {
      this.isShowModal = true;
    },
    handleConfirmSuccess() {
      const statusOrder = JSON.stringify({
        status_order: {
          status: 5,
          statusChange:5,
          status_list: {
            id: 1,
            status: true,
            organization: 2,
            status_convert: 3,
            title_status_order: "Giao hàng thành công",
          },
        },
      });
      HTTP.put("/v1/api/order/" + this.$route.params.id + "/", statusOrder)
        .then(() =>
          this.$router.push({ path: "/quan-ly-order/" + this.dataOrder.id })
        )
        .catch(error => console.log(error))
        var data = new FormData()
        data.append('title_logorder',"Giao hàng thành công")
        data.append('note',this.note)
        data.append('user_create',parseInt(localStorage.getItem("userId")))
        data.append('order',parseInt(this.$route.params.id))
        data.append('image',this.fileUpload[0])
      HTTP.post("/v1/api/logorder/", data).then((res) => {
        const transData = JSON.stringify({
          key: "status_order",
          order_key: this.dataOrder.order_key,
          shipper: {
            id: 1,
          },
          status_convert_pre: 2,
          status_convert: 3,
          title_logorder: res.data.title_logorder,
          created_at: res.data.created_at,
          total_price: this.dataOrder.total_price,
        });
        console.log(transData);
        HTTP.post("/contact/send/", transData).then((res) => {
          console.log(transData, "thanhcong");
          console.log(res);
        });
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style scoped lang="scss">
@import "DeliverySuccessView.scss";
</style>    
<template>
    <div class="f jcc aic cart-out-popup">
        <div class="cart-out-container border-radius bg-white-1">
            <div class="cart-out__header pb1 border-bottom">
                <div class="cart-out__header-title h8 text-red-linear mb1">
                    Rút tiền khỏi ví
                </div>
                <div class="cart-out__header-money f jcb aic mb1">
                    <div class="h6 gray">Số dư Ví BZIE</div>
                    <div class="text-red-linear h7">{{walletBalance}} VND</div>
                </div>
                <div class="cart-out__header-input">
                    <label class="p4" for="">Số tiền cần rút</label>
                    <div class="relative w100 mt05">
                        <div class="header-input__icon absolute gray">VND</div>
                        <input 
                            class="w100 pt075 pb075 pl075" 
                            type="number"
                            placeholder="0"
                            @input="handleCashOut($event.target)"
                        >
                    </div>
                    <div
                        v-if="handleCash && (handleCash < conditionPrice)"
                        class="mt025 p-navsmall red-1 header-input__error"
                    >
                     {{message}}
                    </div>
                </div>
            </div>

            <div class="cart-out__body pt1 mb1">
                <div class="cart-out__body-title text-red-linear h8 mb1">
                    Phương thức nhận tiền
                </div>
                <div class="cart-out__body-bank f aic">
                    <div class="bank__img mr1">
                        <img src="../../../assets/vietcombank.png" alt="">
                    </div>
                    <div class="bank__info fdc">
                        <div class="mb025 h8">Nguyễn Thị Bill</div>
                        <div class="mb025 h6 gray">123 456 789 123</div>
                    </div>
                </div>
            </div> 

            <div class="cart-out__btn">
                <div class="cart-out__btn-title h6 mb15 align-c gray">
                    Bằng việc chọn <span class="h7 black">“Rút tiền”</span>, bạn đồng ý với các <span class="h7 blue">Điều khoản và Điều kiện</span> của chúng tôi
                </div>
                <div class="btn-container f aic">
                    <button
                        class="button h8 w50 align-c pt1 pb1 border-radius border button--white"
                        @click="cancel"
                    >
                        Hủy bỏ
                    </button>
                    <button
                        :class="['button h8 w50 align-c pt1 pb1 border-radius ml1 bg-red-1 white button--primary', {'button--disabled': cashWithDrawals && Number(cashWithDrawals < conditionPrice)}]"
                        @click="withdrawMoney"
                    >
                        Rút tiền
                    </button>
                </div>
            </div> 
        </div>

        <div
            v-if="noticeFailed"
            class="noti-faild fdc aic border-radius bg-white-1"
        >
            <div class="successful-noti mb1"></div>
            <div class="h2 text-red-linear mb05">Thông báo</div>
            <div class="p6 gray align-c mb15">Số dư trong ví của bạn chưa đạt tới ngưỡng rút tiền. <span class="h8 blue">Tìm hiểu thêm</span></div>
            <div
                class="w50 pt1 pb1 h8 border-radius border align-c"
                @click="handleClosePopup"
            >
                Quay lại
            </div>
        </div>

        <div
            v-if="noticeSuccess"
            class="noti-success fdc aic border-radius bg-white-1"
        >
            <div class="successful-noti mb1"></div>
            <div class="h2 text-red-linear mb05">Thông báo</div>
            <div class="p6 gray align-c mb15">Yêu cầu rút tiền thành công. Hệ thống BZIE đang xử lí giao dịch của bạn</div>
            <div class="f aic jcb w100">
                <div class="w50 pt1 pb1 h8 border-radius border align-c"
                    @click="handleClosePopup">Quay lại</div>
                <div class="w50 pt1 pb1 ml1 h8 border-radius align-c bg-red-1 white"
                    @click="handleClosePopup">Xem chi tiết</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        message: {
            type: String,
            default: 'Số tiền rút phải lớn hơn 100.000VND'
        }
    },
    data() {
        return {
            showModal: false,
            noticeFailed: false,
            noticeSuccess: false,
            cashWithDrawals: null,
            walletBalance: '1.000.000',
            conditionPrice: 100000
        }
    },
    computed: {
        handleCash() {
            return this.cashWithDrawals
        },
        formatPrice() {
            return `${this.cashWithDrawals}VND`
        }
    },
    watch: {
        cashWithDrawals (newValue) {
            this.cashWithDrawals = Number(newValue).toLocaleString('it-IT', {style : 'currency', currency : 'VND'})
        }
    },
    methods: {
        cancel() {
            this.$emit('cancel', false)
        },
        withdrawMoney() {
            if (this.cashWithDrawals < this.conditionPrice) this.noticeFailed = true
            else this.noticeSuccess = true
            
            // var popup = document.getElementsByClassName('cart-out-container')[0]
            // if (parseInt(this.cashWithDrawals) > this.walletBalance) {
            //     popup.classList.add('hide')
            //     var popupNotiFaild = document.getElementsByClassName('noti-faild')[0]
            //     popupNotiFaild.classList.toggle('active')
            // }
            // else {
            //     popup.classList.add('hide')
            //     var popupNotiSuccess = document.getElementsByClassName('noti-success')[0]
            //     popupNotiSuccess.classList.toggle('active')
            // }
        },
        handleClosePopup() {
            var popup = document.getElementsByClassName('cart-out-popup')[0]
            popup.classList.toggle('active')
            var popup1 = document.getElementsByClassName('cart-out-container')[0]
            popup1.classList.remove('hide')
            var popupNoti = document.getElementsByClassName('noti-success')[0]
            popupNoti.classList.remove('active')
            var popupNoti1 = document.getElementsByClassName('noti-faild')[0]
            popupNoti1.classList.remove('active')
        },
        handleCashOut (target) {
            const formatData = Number(target.value)
            this.cashWithDrawals = formatData.toLocaleString('it-IT', {style : 'currency', currency : 'VND'})
        }
    }
}
</script>

<style scoped lang="scss">
    @import "CartOutPopup.scss";
</style>
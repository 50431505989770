<template>
    <NoticeView>
        <template v-slot:header>
            <h2 class="h2 red-1">Thông báo</h2>
        </template>
        <template>
            <p class="p6 gray align-c">Yêu cầu nạp tiền thành công. Hệ thống BZIE đang xử lí giao dịch của bạn</p>
        </template>
        <template v-slot:footer>
            <button @click="back" class="button button--white notice__cta">Quay lại</button>
            <button @click="confirm" class="button button--primary notice__cta">Xác nhận</button>
        </template>
    </NoticeView>
</template>

<script>
import NoticeView from '@/components/NoticeView/NoticeView.vue'

export default {
    components: {
        NoticeView
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        confirm() {
            this.$emit('close-payment-done', false)
        }
    }
}
</script>
<template>
  <div class="noti-popup jcc aic">
    <div class="noti-container border-radius bg-white-1">
      <div class="noti__header f jcb aic mb15">
        <div>
          <div class="text-red-linear h8">Lợi nhuận BZIE</div>
          <div class="h6 blue">{{ exchangeData.exchange_key }}</div>
        </div>
        <div
          v-if="stateWallet == 'Thất bại'"
          class="item__header-right h7 pt025 pb025 pl05 pr05 border-radius red-2-text bg-red-faild"
        >
          {{ stateWallet }}
        </div>
        <div
          v-if="stateWallet == 'Thành công'"
          class="item__header-right h7 pt025 pb025 pl05 pr05 border-radius green bg-green"
        >
          {{ stateWallet }}
        </div>
        <div
          v-if="stateWallet == 'Đang xử lý'"
          class="item__header-right h7 pt025 pb025 pl05 pr05 border-radius orange bg-yellow"
        >
          {{ stateWallet }}
        </div>
      </div>
      <div class="noti__body mb15">
        <div class="noti__body-info mb15">
          <div class="f jcb aic mb05">
            <div class="h6 gray">Tổng giao dịch</div>
            <div class="h7 ml1 align-r">
              {{
                Number("35000").toLocaleString("it-IT", {
                  style: "currency",
                  currency: "VND",
                })
              }}
            </div>
          </div>
          <div class="f jcb aic mb05">
            <div class="h6 gray">Loại hình giao dịch</div>
            <div class="h7 ml2 align-r">{{ stateWallet }}</div>
          </div>
        </div>
        <div class="noti__body-profit pt15 pb15 border-top">
          <div class="f jcb aic">
            <div class="h6 gray">Số tiền thanh toán</div>
            <div class="h7 ml1 align-r">
              {{
                Number("35000").toLocaleString("it-IT", {
                  style: "currency",
                  currency: "VND",
                })
              }}
            </div>
          </div>
        </div>
        <div class="noti__body-time pt15 mb1 border-top">
          <div class="f jcb aic mb05">
            <div class="h6 gray">Hình thức thanh toán</div>
            <div class="h7 ml1 align-r">Ví BZIE</div>
          </div>
          <div class="f jcb aic mb05">
            <div class="h6 gray">Thời gian khởi tạo</div>
            <div v-if="OrderData" class="h7 ml1 align-r">
              {{
                `${getDate(OrderData.created_at)}/${getMonth(
                  OrderData.created_at
                )}/${getYear(OrderData.created_at)}`
              }}
            </div>
          </div>
          <div class="f jcb aif mb05">
            <div class="h6 gray">Thời gian xác nhận</div>
            <div class="h7 ml2 align-r">
              Giao dịch sẽ được xác nhận khi đơn hàng thành công
            </div>
          </div>
          <router-link v-if="OrderData" :to="`quan-ly-order/${OrderData.id}`">
            <div class="noti__view-order mb15 align-e h7 blue">
              Xem đơn hàng
            </div>
          </router-link>
        </div>
      </div>

      <div class="noti__btn f aic jcc">
        <div
          class="noti__btn-close border-radius border h7"
          @click="handleClose"
        >
          Đóng
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const endpointOrder = "v1/api/order/";
import { HTTP } from "@/http-default";

export default {
  props: {
    stateWallet: {
      type: String,
      default: "",
    },
    exchangeData: {
      type: Object,
      default: () => ({}),
    },
    orderId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      amountEarn: {
        type: String,
        default: "35000",
      },
      OrderData: null,
    };
  },
  created() {
    this.getOrderData();
  },
  methods: {
    async getOrderData() {
      const response = await HTTP.get(`${endpointOrder}${this.orderId}/`);
      this.OrderData = response.data;
    },
    convertDate(time) {
      this.date = new Date(time);
    },
    getDate(time) {
      this.convertDate(time);
      return this.date.getDate();
    },
    getMonth(time) {
      this.convertDate(time);
      return this.date.getMonth() + 1;
    },
    getYear(time) {
      this.convertDate(time);
      return this.date.getFullYear();
    },
    handleClose() {
      this.$emit("close", false);
    },
  },
};
</script>

<style scoped lang="scss">
@import "NotiCartPopup.scss";
</style>

<template>
  <div v-if="userData" class="account relative f fdc h100vh">
    <div class="account__header bg-white f pt1 pr1 pb1 pl1">
      <div @click="myAccount" class="f aic jcc account__avatar">A</div>
      <div class="ml1 account__info">
        <h5 @click="myAccount" class="h5 black">{{userData.full_name}}</h5>
        <span class="p text-red-linear fw-bold">Số dư: 10,660,800</span>
        <span class="ml05 p7 blue">(Xem ví)</span>
      </div>
    </div>
    <div class="f fdc jce pl1 pr1 account__footer">
      <div @click="myAccount" class="f p4 black pt1 pb1 account__footer-item">
        <span class="account__icon icon mb025 icon icon--account-black mr075" />
        Tài khoản của tôi
      </div>
      <div class="f p4 black pt1 pb1 account__footer-item">
        <span class="account__icon icon mb025 icon icon--info mr075" />
        Điều khoản
      </div>
      <div @click="sigOut" class="f p4 black pt1 pb1 account__footer-item">
        <span class="account__icon icon mb025 icon icon--exit mr075" />
        Đăng xuất
      </div>
    </div>
    <NavBarView />
    <NoticeView v-if="isReset">
      <template v-slot:header>
        <h2 class="h2 red-1">Thông báo</h2>
      </template>
      <p class="p6 gray align-c">Bạn có chắc chắn muốn đăng xuất</p>
      <template v-slot:footer>
        <button @click="handleBack" class="button button--white notice__cta">
          Quay lại
        </button>
        <button @click="handleReset" class="button button--primary notice__cta">
          Xác nhận
        </button>
      </template>
    </NoticeView>
  </div>
</template>

<script>
import NavBarView from "@/components/NavBarView/NavBarView.vue";
import NoticeView from "@/components/NoticeView/NoticeView.vue";
import { HTTP } from '@/http-default'

export default {
  name: "AccountView",
  components: {
    NavBarView,
    NoticeView
  },
  data() {
    return {
      userData: null,
      isReset: false
    }
  },
  async created() {
    await this.getUser()
  },
  methods: {
    async getUser() {
      const response = await HTTP.get('/v1/api/shipper/')
      this.userData = response.data.results[0]
    },
    myAccount() {
      this.$router.push({ path: "/quan-li-account/my-account" });
    },
    sigOut() {
      this.isReset = true;
    },
    handleBack() {
      this.isReset = false;
    },
    handleReset() {
      localStorage.clear();
      this.$router.go({ path: "/login" });
    }
  },
};
</script>

<style scoped lang="scss">
@import "AccountView.scss";
</style>
<template>
    <div class="f jcc aic progress-payment">
        <div class="border-radius bg-white-1 progress-payment__inner">
            <h3 class="container-header h8 text-red-linear">Nạp tiền vào ví</h3>
            <p class="container-header h8 mt1 mb025 black">Thanh toán</p>
            <p class="p gray">Vui lòng thanh toán theo thông tin sau</p>
            <div class="f jcc mt2">
                <img src="../../../assets/Account/qr_payment.png" alt="" />
            </div>
            <h3 class="container-header mt1 mb1 h8 text-red-linear">Công ty CP Đầu tư và Phát triển công nghệ cao Nhật Minh</h3>
            <div class="grid grid--2">
                <div class="grid__item mt025 p gray">
                    Ngân hàng
                </div>
                <div class="grid__item mt025 p black fw-bold tar">
                    Vietcombank
                </div>
            </div>
            <div class="grid grid--2">
                <div class="grid__item mt025 p gray">
                    Số tài khoản
                </div>
                <div class="grid__item mt025 p black fw-bold tar">
                    0301000390365
                </div>
            </div>
            <p class="p gray">Nội dung chuyển khoản</p>
            <p class="mt05 p gray">
                Vui lòng nạp tiền theo đúng cú pháp: “Tên đăng nhập + Tổng tiền nạp. Ví dụ: <span class="text-red-linear fw-bold">“0123456789 - 500.000VND”</span>. Mọi phản ánh/thắc mắc vui lòng liên hệ tại đây
            </p>
            <h4 class="container-header h8 mt1 mb025 black">Gửi ảnh xác nhận và chờ phê duyệt</h4>
            <p class="p gray">Vui lòng chụp lại màn hình thanh toán và tải lên</p>
            <div class="mt1 f aic mb1 border-radius bg-gray-5 body__media">
                <div class="h5 mr1 pt05 pb05 pl1 pr1 border-radius bg-blue white">Tải ảnh lên</div>
                <div class="f fdc jcb">
                    <div class="h7">Thêm ảnh</div>
                    <div class="h6 gray">Dung lượng tối đa 2MB</div>
                </div>
            </div>
            <p class="p align-c">Bằng việc chọn <span class="black fw-bold">“Hoàn thành”</span>, bạn đồng ý với các <span class="blue fw-bold">Điều khoản và Điều kiện</span> của chúng tôi</p>
            <div class="container-btn f jcb aic mt15 h8">
                <div 
                    class="w50 pt1 pb1 align-c border-radius border"
                    @click="back"
                >
                    Quay lại
                </div>
                <div @click="done" class="w50 pt1 pb1 ml1 align-c border-radius bg-red-1 white">Hoàn thành</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        back() {
            this.$router.go(-1)
        },
        done() {
            this.$emit('payment-done', true)
        }
    }
}
</script>

<style scoped lang='scss'>
    @import "ProgressPayment.scss";
</style>
import { render, staticRenderFns } from "./NotiMain.vue?vue&type=template&id=8620077e&scoped=true&"
import script from "./NotiMain.vue?vue&type=script&lang=js&"
export * from "./NotiMain.vue?vue&type=script&lang=js&"
import style0 from "./NotiMain.vue?vue&type=style&index=0&id=8620077e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8620077e",
  null
  
)

export default component.exports
<template>
    <div v-if="Order && Order.status_order" class="order-detail relative bg-gray-1">
      <div class="orer-detail__header w100 f jcb aic pt075 pb075 pl1 pr1 bg-white-1" @click="handleBack" >
        <div class="f aic">
          <div class="orer-detail__header-back icon icon--back"></div>
          <div class="h5">Quay lại</div>
        </div>
        <!-- phone-call -->
        <div  class="order-detail__header-call f aic"  v-if="Order.status_order.status && Order.status_order.status >= 3">
          <a class="h8 blue mr05" :href="`tel:${[Order.customer.data.phone]}`">Gọi khách</a>
          <div class="icon icon--phone"></div>
        </div>
      </div>
      <div class="order-detail__body pt15 pl1 pr1">
        <div class="order-body__title h3">Chi tiết đơn hàng</div>
        <!-- Order-body__code -->
        <div class="order-body__code border-radius mt15 mb1 pt15 pb1 pr1 pl1 bg-white-1">
          <div class="order-body__code-header h5">Mã đơn hàng</div>
          <div class="order-body__code-number f jcb aic border border-radius mt1 mb1" >
            <div class="h6 gray">Mã đơn hàng</div>
            <div class="f aic">
              <div class="code-number h8 blue" ref="codeOrder">#{{ Order.order_key }}</div>
              <div class="icon icon--copy ml075" @click="handleCopyCode"></div>
            </div>
          </div>
          <div class="code-number__info">
            <div class="f jcb mb05">
              <div class="h6 gray">Kho lấy hàng</div>
              <div class="h5 align-e">
                <!-- {{ Order.user_create.address }} -->
                BZI Supply
              </div>
            </div>
            <div class="f jcb">
              <div class="h6 gray">Địa chỉ kho</div>
              <div class="h5 align-e w60">
                <!-- {{ Order.user_create.full_name }} -->
                48 Tố Hữu
              </div>
            </div>
          </div>
        </div>
        <div v-if="Order.status_order.status === 1" class="order-body__info-buyer border-radius mb1 bg-white-1" >
          <!-- <div 
                      v-for="(item, key) in Order" 
                      :key="key">
                      <div class="info-buyer__item"
                          v-if="key == 'full_name'">

                      </div>
                  </div> -->
          <div class="info-buyer__title h5 mb1">Phương tiện vận chuyển</div>
          <div class="info-buyer__item w100 f jcb aic mb05">
            <div class="info-buyer__item-left h6 gray">Loại xe</div>
            <div class="info-buyer__item-right h7 align-e">Xe tải</div>
          </div>
          <div class="info-buyer__item w100 f jcb aic mb05">
            <div class="info-buyer__item-left h6 gray">Biển số</div>
            <div class="info-buyer__item-right h7 align-e">29x1 88888</div>
          </div>
          <div class="info-buyer__item w100 f jcb aic mb05">
            <div class="info-buyer__item-left h6 gray"></div>
            <div class="info-buyer__item-change p7 align-e" @click="showVerhicle">
              Thay đổi
            </div>
          </div>
        </div>
        <div v-if="changeVerhicle" class="verhicle-change fixed f jcc aic" id="verhicleChange">
          <div class="verhicle-change__detail pt1 pr1 pl1">
            <div class="f jcb mb1">
              <div class="h5">Thiết lập phương tiện vận chuyển</div>
            </div>
            <div class="verhicle-change__title mb1" >
              <div class="row h6 mb1" v-for="(item, index) in titleVerhicle" :key="index">
                <div>{{item}}</div> 
              </div>
            </div>
            <div class="">
              <div  class="verhicle-change__list f jcb aic  pl05 pr1" v-for="(item, index) in verhicleList" :key="index"  >
                <!-- <div for="item.id"> -->
                  <div class="c">{{ item.id }}</div>
                  <div class="a">{{ item.name }}</div>
                  <div class="b">{{ item.number }}</div>
                  <input type="radio" :id="`toggle${index}`" class="000" value="One" />
                  <label :for="`toggle${index}`"></label>
                <!-- </div> -->
              </div>
            </div>
            <div class="f  pt1 ">
              <button @click="closeVerhicle" class="button button--white notice__cta mr05">
                Quay lại
              </button>
              <button @click="accepVerhicle" class="button button--primary notice__cta ml05">
                Xác nhận
              </button>
            </div>
          </div>
        </div>
        <!-- Order-body__status -->
        <div class="Order-body__status-container">
          <!-- Chờ nhận -->
          <div  v-if="Order.status_order.status === 1" :to="{ name: 'order-status' }"
           class="order-body__status f aic jcb mb1 border-radius bg-red-2 red-2-text h7">
            Chờ nhận
          </div>
          <router-link
            v-if="Order.status_order.status === 2"
            :to="{ name: 'order-status', params: { id: Order.id } }"
            class="order-body__status f aic jcb mb1 border-radius bg-yellow orange h7">
            Đang thực hiện (Cần đi lấy hàng)
            <span class="icon icon--back"></span>
          </router-link>
          <router-link
            v-if="Order.status_order.status === 3"
            :to="{ name: 'order-status', params: { id: Order.id } }"
            class="order-body__status f aic jcb mb1 border-radius bg-yellow orange h7">
            {{ Order.status_order.status_list.title_status_order }}
            <span class="icon icon--back"></span>
          </router-link>
          <router-link
            v-if="Order.status_order.status === 12"
            :to="{ name: 'order-status', params: { id: Order.id } }"
            class="order-body__status f aic jcb mb1 border-radius bg-green orange h7">
            Hoàn thành
            <span class="icon icon--back"></span>
          </router-link>
          <router-link
            v-if="Order.status_order.status === 58"
            :to="{ name: 'order-status', params: { id: Order.id } }"
            class="order-body__status f aic jcb mb1 border-radius bg-red red-2-text h7">
            {{ Order.status_order.status_list.title_status_order }}
            <span class="icon icon--back"></span>
          </router-link>
          <!-- Giao hàng thành công -->
          <router-link
            v-if="Order.status_order.status === 5"
            :to="{ name: 'order-status', params: { id: Order.id } }"
            class="order-body__status f aic jcb mb1 border-radius bg-green green h7">
            {{ Order.status_order.status_list.title_status_order }}
            <span class="icon icon--back"></span>
          </router-link>
          <router-link
            v-if="Order.status_order.status === 15"
            :to="{ name: 'order-status', params: { id: Order.id } }"
            class="order-body__status f aic jcb mb1 border-radius bg-red-faild red-2-text h7">
            Đã huỷ (Đã trả hàng về kho)
            <span class="icon icon--back"></span>
          </router-link>
          <router-link
            v-if="Order.status_order.status === 16"
            :to="{ name: 'order-status', params: { id: Order.id } }"
            class="order-body__status f aic jcb mb1 border-radius bg-yellow red-2-text h7">
            Đã huỷ (Không nhận đơn)
            <span class="icon icon--back"></span>
          </router-link>
          <!-- Đang thực hiện (đang gia hàng) -->
          <router-link
            v-if="Order.status_order.status === 4"
            :to="{ name: 'order-status', params: { id: Order.id } }"
            class="order-body__status f aic jcb mb1 border-radius bg-yellow orange h7">
            {{ Order.status_order.status_list.title_status_order }}
            <span class="icon icon--back"></span>
          </router-link>
          <router-link
            v-if="Order.status_order.status === 51"
            :to="{ name: 'order-status', params: { id: Order.id } }"
            class="order-body__status f aic jcb mb1 border-radius bg-yellow orange h7">
            Đang thực hiện (Đang trả hàng về kho)
            <span class="icon icon--back"></span>
          </router-link>
          <router-link
            v-if="Order.status_order.status === 19"
            :to="{ name: 'order-status', params: { id: Order.id } }"
            class="order-body__status f aic jcb mb1 border-radius bg-yellow orange h7">
            Đang thực hiện (Đã trả hàng về kho)
            <span class="icon icon--back"></span>
          </router-link>
          <router-link
            v-if="Order.status_order.status === 20"
            :to="{ name: 'order-status', params: { id: Order.id } }"
            class="order-body__status f aic jcb mb1 border-radius bg-yellow red-2-text h7">
            Đã huỷ (Đang trả hàng về kho)
            <span class="icon icon--back"></span>
          </router-link>
        </div>
        <div v-if="Order.status_order.status === 1">
          <div
            v-if="
              packageInfo &&
              packageInfo.hasOwnProperty('weight') &&
              packageInfo.weight.hasOwnProperty('weight_measure')
            "
            class="order-body__package-information border-radius mb1 bg-white-1"
          >
            <div class="info-buyer__title h5 mb1">Thông tin kiện hàng</div>
            <div class="info-buyer__item w100 f jcb aic mb05">
              <div class="info-buyer__item-left h6 gray">Khối lượng</div>
              <div class="info-buyer__item-right h7 align-e">
                  {{ packageInfo.weight.weight }} {{ packageInfo.weight.dimensions_measure }}
              </div>
            </div>
            <div class="info-buyer__item w100 f jcb aic mb05">
              <div class="info-buyer__item-left h6 gray">Kích thước ({{packageInfo.dimensions.dimensions_measure}}) </div>
              <div class="info-buyer__item-right h7 align-e">
                {{ packageInfo.dimensions.height }}x
                {{ packageInfo.dimensions.length }}
                x{{ packageInfo.dimensions.width }}
              </div>
            </div>
            <div class="info-buyer__item w100 f jcb aic mb05">
              <div class="info-buyer__item-left h6 gray">Ghi chú</div>
              <div class="info-buyer__item-right h7 align-e">
                   {{ packageInfo.note }}
              </div>
            </div>
          </div>
        </div>
        <!-- Order-body__info-billing -->
        <div
          v-if="Order.status_order.status !== 1"
          class="order-body__info-billing mb1"
        >
          <div class="order-body__info-buyer border-radius mb1 bg-white-1">
            <div class="info-buyer__title h5 mb1">Thông tin người mua</div>
            <div v-if="Order.customer.data.full_name" class="info-buyer__item w100 f jcb aic mb05">
              <div class="info-buyer__item-left h6 gray">Tên người mua</div>
                <div class="info-buyer__item-right h7 align-e">
                  {{Order.customer.data.full_name}}
                </div>
            </div>
             <div v-if="Order.customer.data.email" class="info-buyer__item w100 f jcb aic mb05">
              <div class="info-buyer__item-left h6 gray">Email</div>
              <div class="info-buyer__item-right h7 align-e">
                {{Order.customer.data.email}}
              </div>
            </div>
            <div v-if="Order.customer.data.phone" class="info-buyer__item w100 f jcb aic mb05">
              <div class="info-buyer__item-left h6 gray">Số điện thoại</div>
              <div class="info-buyer__item-right h7 align-e">
                {{Order.customer.data.phone}}
              </div>
            </div>
            <div v-if="Order.customer.data.address" class="info-buyer__item w100 f jcb aic mb05">
              <div class="info-buyer__item-left h6 gray">Địa chỉ</div>
              <div class="info-buyer__item-right h7 align-e">
                {{Order.customer.data.address}}
              </div>
            </div>
            <div class="info-buyer__item w100 f jcb aic mb05">
              <div class="info-buyer__item-left h6 gray">Ghi chú</div>
              <div class="info-buyer__item-right h7 align-e">
                {{Order.note}}
              </div>
            </div>
          </div>
          <!-- Order-body__package-information -->
          <div
            v-if="
              packageInfo &&
              packageInfo.hasOwnProperty('weight') &&
              packageInfo.weight.hasOwnProperty('weight_measure')
            "
            class="order-body__package-information border-radius mb1 bg-white-1"
          >
            <div class="info-buyer__title h5 mb1">Thông tin kiện hàng</div>
            <div class="info-buyer__item w100 f jcb aic mb05">
              <div class="info-buyer__item-left h6 gray">Khối lượng </div>
              <div class="info-buyer__item-right h7 align-e">
                {{ packageInfo.weight.weight }} {{ packageInfo.weight.weight_measure }}
              </div>
            </div>
            <div class="info-buyer__item w100 f jcb aic mb05">
              <div class="info-buyer__item-left h6 gray">Kích thước ({{packageInfo.dimensions.dimensions_measure}}) </div>
              <div class="info-buyer__item-right h7 align-e">
                {{ packageInfo.dimensions.height }} x
                {{ packageInfo.dimensions.length }}x
                {{ packageInfo.dimensions.width }}
              </div>
            </div>
            <div class="info-buyer__item w100 f jcb aic mb05">
              <div class="info-buyer__item-left h6 gray">Ghi chú</div>
              <div class="info-buyer__item-right h7 align-e">
                {{ packageInfo.note }}
              </div>
            </div>
          </div>

          <div class="pt1 pr1 pb1 pl1 bg-white-1">
            <div class="f jcb">
              <div class="h5 mb1">Thông tin thanh toán </div>
               <div class="h8 red mb1">Thanh toán COD </div>
            </div>
              <div v-for="(item, index) in billingInfor" :key="index" class="info__product-item">
                <div class="info-billing__list-product">
                  <div class="propduct__item pb1 mb1 border-bottom">
                    <div class="propduct__item-header f aic mb1">
                      <img src="../../assets/imgUser.png" alt="" />
                      <div class="f fdc jcb ml075">
                        <div class="h7">{{ item.product.title_product }}</div>
                        <div class="h6 gray">{{ item.product.key_product }}</div>
                      </div>
                    </div>
                    <div class="propduct__item-info">
                      <div class="info-buyer__item w100 f jcb aic mt05">
                        <div class="info-buyer__item-left h6 gray">Số lượng</div>
                        <div class="info-buyer__item-right h7 align-e">
                          {{ item.quantity }}
                        </div>
                      </div>
                      <div class="info-buyer__item w100 f jcb aic mt05">
                        <div class="info-buyer__item-left h6 gray">Đơn giá</div>
                        <div class="info-buyer__item-right h7 align-e">
                          {{ item.product.price_product }}
                        </div>
                      </div>
                      <div class="info-buyer__item w100 f jcb aic mt05">
                        <div class="info-buyer__item-left h6 gray">Thành tiền</div>
                        <div class="info-buyer__item-right h7 align-e">
                          {{ item.total_price }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="info-billing__total">
                <div class="info-buyer__item w100 f jcb aic mt05">
                  <div class="info-buyer__item-left h6 gray">Tổng tiền hàng</div>
                  <div class="info-buyer__item-right h7 align-e">
                    {{
                      totalPrice.toLocaleString("it-IT", {
                        style: "currency",
                        currency: "VND",
                      })
                    }}
                  </div>
                </div>
                <div class="info-buyer__item w100 f jcb aic mt05">
                  <div class="info-buyer__item-left h6 gray">Phí vận chuyển</div>
                  <div class="info-buyer__item-right h7 align-e">35.000VND</div>
                </div>
                     <div class="info-buyer__item w100 f jcb aic mt05">
                  <div class="info-buyer__item-left h6 gray">Tổng đơn hàng</div>
                  <div class="info-buyer__item-right mt025 h7 red tar">
                    {{
                      (totalPrice + 35000).toLocaleString("it-IT", {
                        style: "currency",
                        currency: "VND",
                      })
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <!-- Order-detail__action -->
      <!-- Chờ nhận -->
      <div class="order-detail__action" v-if="Order.status_order.status === 1">
        <div class="order-detail__action-total f aic jcb pt1 pb1 pr1 pl1 bg-gray-1">
          <div v-if="Order.status_order.status === 1" class="w50 border-right">
            <div class="h6 gray">Tiền cần thu</div>
            <div class="h6"><p class="mt025 h6 black">
              {{
                (totalPrice + 35000).toLocaleString("it-IT", {
                  style: "currency",
                  currency: "VND",
                })
              }}
            </p></div>
          </div>
          <!-- <div v-else class="w50 border-right">
            <div class="h6 gray">Tiền đã cọc</div>
            <div class="h6">{{ Order.shipper_profit }}</div>
          </div> -->
          <div class="w50 align-e">
            <div class="h6 gray">Lợi nhuận</div>
            <div class="total-profit h7">
              <!-- {{ Order.shipper_profit }} -->
              35.000 VND
            </div>
          </div>
        </div>
        <div
          v-if="Order.status_order.status === 1"
          class="order-detail__action-btn pt1 pb1 pl1 pr1 bg-white-1"
        >
          <div
            class="btn btn-action w100 h5 pt1 pb1 border-radius align-c bg-red-1 white"
            @click="handleGetOrder"
          >
            Nhận đơn
          </div>
          <div class="mt05 h6 align-c gray">
            Bằng việc chọn “Nhận đơn”, bạn đồng ý với các <br />
            <span class="h7 blue">Điều khoản và Điều kiện</span> của chúng tôi
          </div>
        </div>
      </div>
      <!-- Đang thực hiện (Cần đi lấy hàng) -->
      <div
        v-if="Order.status_order.status === 2"
        class="order-detail__action bg-gray-1"
      >
        <div class="grid grid--detail-footer bg-gray-3 order-detail__footer-top">
          <div class="grid__item">
            <h6 class="h6 gray">Tiền đặt cọc</h6>
            <p class="mt025 h6 black">
              {{
                totalPrice.toLocaleString("it-IT", {
                  style: "currency",
                  currency: "VND",
                })
              }}
            </p>
          </div>
          <div class="grid__item">
            <h6 class="h6 gray">Tiền cần thu</h6>
            <p class="mt025 h6 black">
              {{
                (totalPrice + 35000).toLocaleString("it-IT", {
                  style: "currency",
                  currency: "VND",
                })
              }}
            </p>
          </div>
          <div class="grid__item">
            <h6 class="h6 gray tar">Lợi nhuận</h6>
            <p class="mt025 h7 red tar">
              <!-- {{ Order.shipper_profit }} -->
              35.000VND
            </p>
          </div>
        </div>
        <!-- <div class="pt1 pl1 pr1">
          <div class="btn-help w100 h5 pt1 pb1 border-radius border align-c bg-white-1">
            Hỗ trợ
          </div>
        </div> -->
        <div class="order-detail__action-btn f aic jcb pt1 pb1 pl1 pr1 bg-gray-1">
          <div class="btn-cancel w100 h5 pt1 pb1 border-radius border align-c bg-white-1">
            <button @click="handleCancel" class="h5">Hủy nhận đơn</button>
          </div>
          <div class="btn-action w100 h5 ml1 pt1 pb1 border-radius align-c bg-red-1 white"
            @click="handleConfirmPickUpOrder">
            Đã lấy hàng
          </div>
        </div>
        <div class="f jcc aic pb05"  >
          <div class="h8 blue">Bạn cần hỗ trợ?</div>
        </div>
      </div>
      <!-- Đang thực hiện (Chờ giao hàng) -->
      <div v-if="Order.status_order.status === 3" class="bg-gray-1 order-detail__footer">
        <div class="grid grid--detail-footer bg-gray-3 order-detail__footer-top">
          <div class="grid__item">
            <h6 class="h6 gray">Tiền đặt cọc</h6>
            <p class="mt025 h6 black">
              {{
                totalPrice.toLocaleString("it-IT", {
                  style: "currency",
                  currency: "VND",
                })
              }}
            </p>
          </div>
          <div class="grid__item">
            <h6 class="h6 gray">Tiền cần thu</h6>
            <p class="mt025 h6 black"> {{
                (totalPrice + 35000).toLocaleString("it-IT", {
                  style: "currency",
                  currency: "VND",
                })
              }}</p>
          </div>
          <div class="grid__item">
            <h6 class="h6 gray tar">Lợi nhuận</h6>
            <p class="mt025 h7 red tar">
              <!-- {{ Order.shipper_profit }} -->
              35.000VND
            </p>
          </div>
        </div>
        <div class="pt1 pb1 pl1 pr1">
          <!-- <div class="btn-help w100 h5 pt1 pb1 border-radius border align-c bg-white-1">
            Hỗ trợ
          </div> -->
          <div class="order-detail__action-btn f aic jcb pt1 pb1 bg-gray-1">
            <div class="btn-cancel w100 h5 pt1 pb1 border-radius border align-c bg-white-1">
              <button @click="handleCancel" class="h5">Hủy nhận đơn</button>
            </div>
            <div class="btn-action w100 h5 ml1 pt1 pb1 border-radius align-c bg-red-1 white" @click="handleDelivery" >
              Tiến hành giao
            </div>
          </div>
          <div class="f jcc aic pb05">
            <div class="h8 blue">Bạn cần hỗ trợ?</div>
          </div>
        </div>
      </div>

      <!-- Đang thực hiện (Đã lấy hàng)-->
      <div class="order-detail__action bg-gray-1" v-if="Order.status_order.status === 4">
        <div class="order-detail__action-total f aic jcb mb1 pt1 pb1 pr1 pl1 bg-gray-2">
          <div class="w50 border-right">
            <div class="h6 gray">Tiền đặt cọc</div>
            <div class="h6">
              {{
                totalPrice.toLocaleString("it-IT", {
                  style: "currency",
                  currency: "VND",
                })
              }}
            </div>
          </div>
          <div class="w50 align-c border-right">
            <div class="h6 gray">Tiền cần thu</div>
            <div class="h6"> {{
                (totalPrice + 35000).toLocaleString("it-IT", {
                  style: "currency",
                  currency: "VND",
                })
              }}</div>
          </div>
          <div class="w50 align-e">
            <div class="h6 gray">Lợi nhuận</div>
            <div class="total-profit h7">
              <!-- {{ Order.shipper_profit }} -->
              35.000 VND
            </div>
          </div>
        </div>
        <div class="order-detail__action-btn f aic jcb pl1 pr1 bg-gray-1">
          <!-- <div class="btn-cancel w100 h5 pt1 pb1 border-radius border align-c bg-white-1">
            Hỗ trợ
          </div> -->
           <div class="btn-cancel w100 h5 pt1 pb1 border-radius border align-c bg-white-1">
            <router-link  :to="{ name: 'delivery-fail', params: { id: Order.id } }" class="black">
              <span @click="deliveryFailed"> Giao thất bại</span>
            </router-link>
          </div>
          <div class="btn-cancel w100 h5 pt1 ml1 pb1 border-radius border align-c bg-white-1">
            <button @click="handleCancel" class="h5">Hủy nhận đơn</button>
          </div>
        </div>
        <div class="order-detail__action-btn f aic jcb pt1 pb1 pl1 pr1 bg-gray-1">
          <div class="btn-action w100 h5  pt1 pb1 border-radius align-c bg-red-1 white">
            <router-link :to="{ name: 'delivery-success', params: { id: Order.id } }" class="white" >
              <span @click="handleWallet">Giao thành công</span>
            </router-link>
          </div>
        </div>
        <div class="f jcc aic pb05" >
            <div class="h8 blue">Bạn cần hỗ trợ?</div>
        </div>
      </div>
      <!-- Giao hàng thành công -->
      <div v-if="Order.status_order.status === 5" class="bg-gray-1 order-detail__footer">
        <div class="grid grid--detail-footer bg-gray-3 order-detail__footer-top">
          <div class="grid__item">
            <h6 class="h6 gray">Tiền đặt cọc</h6>
            <p class="mt025 h6 black">
              {{
                totalPrice.toLocaleString("it-IT", {
                  style: "currency",
                  currency: "VND",
                })
              }}
            </p>
          </div>
          <!-- <div class="grid__item">
            <h6 class="h6 gray">Tiền cần thu</h6>
            <p class="mt025 h6 black">{{ Order.deposit_amount }}</p>
          </div> -->
          <div class="grid__item">
            <h6 class="h6 gray tar">Lợi nhuận</h6>
            <p class="mt025 h7 red tar">
              <!-- {{ Order.shipper_profit }} -->
              35.000 VND
            </p>
          </div>
        </div>
      </div>

      <!-- Đã huỷ (Đang trả hàng về kho) -->
      <div class="order-detail__action bg-gray-1" v-if="Order.status_order.status === 52">
        <div class="order-detail__action-total f aic jcb pt1 pb1 pr1 pl1 bg-gray-2">
          <div class="w50 border-right">
            <div class="h6 gray">Tiền cần thu</div>
            <div class="h6">{{ Order.deposit_amount }}</div>
          </div>
          <div class="w50 align-c border-right">
            <div class="h6 gray">Tiền cần thu</div>
            <div class="h6">{{ Order.shipper_profit }}</div>
          </div>
          <div class="w50 align-e">
            <div class="h6 gray">Lợi nhuận</div>
            <div class="total-profit h7">
              <!-- {{ Order.shipper_profit }} -->
              35.000 VND
            </div>
          </div>
        </div>
        <div class="order-detail__action-btn f fdc aic jcb pt1 pb1 pl1 pr1 bg-gray-1">
          <div class="btn-cancel w100 h5 pt1 pb1 border-radius border align-c bg-white-1">
            <router-link :to="{ name: 'delivery-fail', params: { id: Order.id } }">
              Hỗ trợ
            </router-link>
          </div>
          <div v-if="Order.status_order.status === 53" @click="handleReturnStore" class="btn-action w100 h5 mt1 pt1 pb1 border-radius align-c bg-red-1 white">
            Đã trả về kho
          </div>
        </div>
      </div>
      <!-- Đang thực hiện (Đang trả hàng về kho) -->
      <div class="order-detail__action bg-gray-1"
        v-if=" Order.status_order.status && (Order.status_order.status === 18 || Order.status_order.status === 19)">
        <div class="order-detail__action-total f aic jcb pt1 pb1 pr1 pl1 bg-gray-2">
          <div class="w50 border-right">
            <div class="h6 gray">Tiền cần thu</div>
            <div class="h6">{{ Order.deposit_amount }}</div>
          </div>
          <div class="w50 align-c border-right">
            <div class="h6 gray">Tiền cần thu</div>
            <div class="h6">{{ Order.shipper_profit }}</div>
          </div>
          <div class="w50 align-e">
            <div class="h6 gray">Lợi nhuận</div>
            <div class="total-profit h7">
              <!-- {{ Order.shipper_profit }} -->
              35.000 VND
            </div>
          </div>
        </div>
        <div class="order-detail__action-btn f aic jcb pt1 pb1 pl1 pr1 bg-gray-1">
          <div class="btn-cancel w100 h5 pt1 pb1 border-radius align-c">
            <button class="button button--white">Hỗ trợ</button>
            <button v-if="Order.status_order.status === 18" @click="handleReturnWarehouse" class="w100 mt1 button button--primary">
              Đã trả hàng về kho
            </button>
            <button v-else @click="confirmCancelOrder" class="w100 mt1 button button--primary">
              Huỷ nhận đơn
            </button>
          </div>
        </div>
      </div>
      <NoticeView v-if="isConfirmPickOrder">
        <template>
          <div class="noti__img successful-noti mb1"></div>
          <h2 class="h2 red-1">Thông báo</h2>
        </template>
        <template>
          <div class="p6 gray">
            Xác nhận đã lấy hàng cọc
            {{
              totalPrice.toLocaleString("it-IT", {
                style: "currency",
                currency: "VND",
              })
            }}
          </div>
        </template>
        <template v-slot:footer>
          <button @click="handleBack" class="button button--white notice__cta">
            Quay lại
          </button>
          <button @click="handlePickUpOrder" class="button button--primary notice__cta">
            Xác nhận
          </button>
        </template>
      </NoticeView>
      <NoticeView v-if="isCancel">
        <template v-slot:header>
          <h2 class="h2 red-1">Thông báo</h2>
        </template>
        <template>
          <div class="p6 gray">
            <div class="f fdc aic mb1">
              <div class="f fdc aic error-notice">
                <span class="warning-icon"></span>
              </div>
              <h2 class="mt1 h2 red-1">Thông báo</h2>
            </div>
            Chọn <strong class="h5 black">“Xác nhận”</strong> tương ứng với việc
            <strong class="h5 black">Hủy đơn hàng</strong> và bạn đang đồng ý với
            các Điều khoản và Điều kiện của chúng tôi
          </div>
        </template>
        <template v-slot:footer>
          <button @click="handleBack" class="button button--white notice__cta">
            Quay lại
          </button>
          <button @click="handleConfirmCancel" class="button button--primary notice__cta">
            Xác nhận
          </button>
        </template>
      </NoticeView>
      <NoticeView v-if="isSuccessCancel">
        <div class="f fdc aic mb1">
          <div class="f fdc aic error-notice">
            <span class="error-icon"></span>
          </div>
          <h2 class="mt1 h2 red-1">Thông báo</h2>
        </div>
        <template>
          <div class="p6 gray">Huỷ đơn thành công</div>
        </template>
        <template v-slot:footer>
          <button @click="handleSuccessCancelOrder" class="button button--white notice__cta" >
            Đóng
          </button>
        </template>
      </NoticeView>
      <NoticeView v-if="isPickOrder">
        <template v-slot:header>
          <h2 class="h2 red-1">Thông báo</h2>
          <h2 class="mt05 h2 green">Bạn đã nhận hàng</h2>
        </template>
        <template>
          <div class="w100 mt1 body__media f aic mb1 border-radius bg-gray-5">
            <div class="h5 mr1 pt05 pb05 pl1 pr1 border-radius bg-blue white">
              Tải ảnh lên
            </div>
            <div class="f fdc jcb">
              <div class="h7">Thêm ảnh</div>
              <div class="h6 gray">Dung lượng tối đa 2MB</div>
            </div>
          </div>
          <div class="w100 body__note mb15">
            <div class="body__note-title p4">Chú thích</div>
            <textarea  class="h7 pt05 pb05 pl075 pr075" name="" id="" cols="30" rows="10" placeholder="Thêm ghi chú"></textarea>
          </div>
          <div class="w100">
            <h5 class="h5 black mb1">Vui lòng đánh giá dịch vụ kho</h5>
            <div class="f aic jcb mt05 order-review">
              <h6 class="h6">Tiêu chí 1</h6>
              <div class="f star-list">
                <div class="star-list" v-for="(item, key) in 5" :key="key">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M6 9.95016L2.87713 11.5436C2.50946 11.7313 2.08567 11.4177 2.1575 11.0113L2.75 7.65836L0.254009 5.29695C-0.0502481 5.00909 0.113068 4.49713 0.527809 4.43864L3.99139 3.95016L5.55431 0.876499C5.73965 0.512004 6.26035 0.512003 6.44569 0.876499L8.00861 3.95016L11.4722 4.43864C11.8869 4.49713 12.0502 5.00909 11.746 5.29695L9.25 7.65836L9.8425 11.0113C9.91433 11.4177 9.49054 11.7313 9.12287 11.5436L6 9.95016Z"
                      fill="#C7C9D9"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div class="f aic jcb mt05 order-review">
              <h6 class="h6">Tiêu chí 2</h6>
              <div class="f star-list">
                <div class="star-list" v-for="(item, key) in 5" :key="key">
                  <svg  width="12"  height="12"  viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6 9.95016L2.87713 11.5436C2.50946 11.7313 2.08567 11.4177 2.1575 11.0113L2.75 7.65836L0.254009 5.29695C-0.0502481 5.00909 0.113068 4.49713 0.527809 4.43864L3.99139 3.95016L5.55431 0.876499C5.73965 0.512004 6.26035 0.512003 6.44569 0.876499L8.00861 3.95016L11.4722 4.43864C11.8869 4.49713 12.0502 5.00909 11.746 5.29695L9.25 7.65836L9.8425 11.0113C9.91433 11.4177 9.49054 11.7313 9.12287 11.5436L6 9.95016Z"
                      fill="#C7C9D9"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div class="f aic jcb mt05 order-review">
              <h6 class="h6">Tiêu chí 3</h6>
              <div class="f star-list">
                <div class="star-list" v-for="(item, key) in 5" :key="key">
                  <svg  width="12" height="12" viewBox="0 0 12 12" fill="none"  xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6 9.95016L2.87713 11.5436C2.50946 11.7313 2.08567 11.4177 2.1575 11.0113L2.75 7.65836L0.254009 5.29695C-0.0502481 5.00909 0.113068 4.49713 0.527809 4.43864L3.99139 3.95016L5.55431 0.876499C5.73965 0.512004 6.26035 0.512003 6.44569 0.876499L8.00861 3.95016L11.4722 4.43864C11.8869 4.49713 12.0502 5.00909 11.746 5.29695L9.25 7.65836L9.8425 11.0113C9.91433 11.4177 9.49054 11.7313 9.12287 11.5436L6 9.95016Z"
                      fill="#C7C9D9"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div class="w100 mt1 body__note mb1">
              <div class="body__note-title p4">Ghi chú</div>
              <textarea class="h7 pt05 pb05 pl075 pr075" name="" id="" cols="30" rows="10" placeholder="Thêm nhận xét"></textarea>
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="notice__footer-content">
            <div class="mt1 h6 align-c gray">
              Bạn có trách nhiệm bảo quản và chuyển giao kiện hàng, theo
              <a href="#" class="h7 blue">Điều khoản và Điều kiện</a> của chúng tôi
            </div>
            <div class="delivery-fail__btn w100 mt2 f jcb aic bg-white-1">
              <div @click="hideNotice" class="w50 h8 pt1 pb1 border align-c border-radius">
                Huỷ bỏ
              </div>
              <div @click="moveToWaitOrder" class="w50 h8 pt1 pb1 ml05 align-c border-radius bg-red-1 white"  >
                Tôi đã hiểu
              </div>
            </div>
          </div>
        </template>
      </NoticeView>
      <NoticeView v-if="isReturnStore">
        <template>
          <div class="f fdc aic mb1">
            <div class="f fdc aic error-notice">
              <span class="error-icon"></span>
            </div>
          </div>
          <h2 class="h2 red-1">Thông báo</h2>
          <p class="mt05 p6 gray">
            Xác nhận đã gửi hàng về kho {{ Order.warehouse }}<br />
            và nhận lại {{ Order.shipper_profit }} tiền cọc
          </p>
          <div class="w100 h6 gray mt1">Vui lòng thêm ảnh đối chứng</div>
        </template>
        <template>
          <div class="w100 mt1 body__media f aic mb1 border-radius bg-gray-5">
            <div class="h5 mr1 pt05 pb05 pl1 pr1 border-radius bg-blue white">
              Tải ảnh lên
            </div>
            <div class="f fdc jcb">
              <div class="h7">Thêm ảnh</div>
              <div class="h6 gray">Dung lượng tối đa 2MB</div>
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="w100 notice__footer-content">
            <div class="delivery-fail__btn w100 mt2 f jcb aic bg-white-1">
              <div @click="hideNotice" class="w50 h8 pt1 pb1 border align-c border-radius">
                Quay lại
              </div>
              <div @click="cancelOrder" class="w50 h8 pt1 pb1 ml05 align-c border-radius bg-red-1 white">
                Xác nhận
              </div>
            </div>
          </div>
        </template>
      </NoticeView>
    </div>
</template>

<script>
import { HTTP } from "../../http-default";
import NoticeView from "@/components/NoticeView/NoticeView.vue";
import { STATUS_ORDER } from "@/contant/config";
const stateWalletEndpoint = "/v1/api/exchange/";
const endpointWallet = "v1/api/wallet/";
const shipperId = localStorage.getItem("shipper");

export default {
  components: {
    NoticeView,
  },
  data() {
    return {
      titleVerhicle:[
        "STT",
        "Tên loại xe",
        "Biển số",
        ""
      ],
      verhicleList: [
        { id: "1", name: "Xe máy", number: "29x1-33333" },
        { id: "2", name: "Xe ba gác", number: "29x1-33333" },
        { id: "3", name: "Xe tải", number: "29x1-33333" },
      ],
      changeVerhicle: false,
      isCancel: false,
      isSuccessCancel: false,
      isConfirmPickOrder: false,
      isPickOrder: false,
      isNotice: false,
      isReturnStore: false,
      isReturnWareHouse: false,
      Order: {},
      STATUS_ORDER: STATUS_ORDER,
      logOderchange: {},
      packageInfo: {},
      billingInfor: {},
      totalPrice: 0,
      wallet: null,
      wid:"",
    };
  },
  computed: {
    getCustomer () {
      return this.Order.customer.data
    }
   
  },
  // watch:{
  //   'changeVerhicle': function() {
  //     if(this.changeVerhicle === true){
  //       this.setWidth();
  //     }
  //   }
  // },
  methods: {
    closeVerhicle(){
      this.changeVerhicle = false
    },
    accepVerhicle(e){
      console.log(e);
    },
    // setWidth(){
    //   var offWidth = document.getElementById("verhicleChange");
    //   var a = document.getElementsByClassName('verhicle-change__detail')[0]
    //   console.log(a,"duo");
    //   let widthOff = offWidth.offsetWidth;
    //   this.wid =  (widthOff-32)/4
    // }, 
    showVerhicle() {
      this.changeVerhicle = true;
    },
    handleCopyCode() {
      const copyText = this.$refs.codeOrder.innerText;
      const TempText = document.createElement("input");
      TempText.value = copyText;
      document.body.appendChild(TempText);
      TempText.select();
      document.execCommand("copy");
      document.body.removeChild(TempText);
    },
    async getData() {
      HTTP.get("/v1/api/order/").then((res) => {
        this.ListOrder = res.data.results;
        for (let i = 0; i < this.ListOrder.length; i++) {
          if (this.ListOrder[i].id == this.$route.params.id) {
            this.Order = this.ListOrder[i];
          }
          this.ListOrder[i].deposit_amount = this.ListOrder[
            i
          ].deposit_amount.toLocaleString("it-IT", {
            style: "currency",
            currency: "VND",
          });
          this.ListOrder[i].shipper_profit = this.ListOrder[
            i
          ].shipper_profit.toLocaleString("it-IT", {
            style: "currency",
            currency: "VND",
          });
        }
        const packageKey = this.Order.package;
        HTTP.get("/v1/api/package/" + packageKey + "/").then((res) => {
          this.packageInfo = res.data;
          const packageId = JSON.stringify({
            package_id: this.packageInfo.id,
          });
          HTTP.post("/getpackageitems/", packageId)
            .then((res) => {
              this.billingInfor = res.data;
              console.log(res.data);

              res.data.forEach((a) => {
                this.totalPrice += a.total_price;
              });
            })
            .then(() => {
              for (let i = 0; i < this.billingInfor.length; i++) {
                this.billingInfor[i].product.price_product = this.billingInfor[i].product.price_product.toLocaleString("it-IT", {
                  style: "currency",
                  currency: "VND",
                });
                this.billingInfor[i].total_price = this.billingInfor[i].total_price.toLocaleString("it-IT", {
                  style: "currency",
                  currency: "VND",
                });
              }
            });
        });
      });
    },
    handleBack() {
      if ((this.Order && this.Order.status_order) && (this.Order.status_order.status == 3 || this.Order.status_order.statusChange == 5)) {
        this.$router.push("/");
      } else {
        this.$router.go(-1);
      }
    },
    handleConfirmCancel() {
      this.isCancel = false;
      this.isSuccessCancel = true;
      const statusOrder = JSON.stringify({
        status_order: {
          status: 5,
          statusChange: 5,
          status_list: {
            id: 1,
            status: true,
            organization: 2,
            status_convert: 5,
            title_status_order: "Đã huỷ (Đang trả hàng về kho)",
          }
        }
      })
      HTTP.put("/v1/api/order/" + this.$route.params.id + "/", statusOrder)
        .then((res) => (this.Order = res.data))
        .catch(error => console.log(error))
    },
    async getWallet() {
      const response = await HTTP.get(endpointWallet);
      this.wallet = response.data.results.filter(
        (item) => item.shipper == shipperId
      )[0];
    },
    handleGetOrder() {
      const statusOrder = JSON.stringify({
        status_order: {
          status: 2,
          statusChange: 2,
          status_list: {
            id: 1,
            status: true,
            organization: 2,
            status_convert: 21,
            title_status_order: "Đang thực hiện (cần đi lấy hàng)"
          }
        }
      })

      const stateWallet = JSON.stringify({
        wallet: this.wallet.id,
        order: this.Order.id,
        status_exchange: 4,
        exchange_type: 1,
        payment_method: null,
      });
      console.log('statusOrder', statusOrder);
      console.log('stateWallet', stateWallet);
      HTTP.put("/v1/api/order/" + this.$route.params.id + "/", statusOrder)
        .then((res) => (this.Order = res.data))
        .catch(error => console.log(error))

      const logOrder = JSON.stringify({
        title_logorder: "Đang thực hiện (cần đi lấy hàng)",
        note: "Note",
        user_create: localStorage.getItem("userId"),
        order: this.$route.params.id,
      });
      console.log(logOrder,"log");
      HTTP.post("/v1/api/logorder/", logOrder).then((res) => {
        const transData = JSON.stringify({
          key: "status_order",
          order_key: this.Order.order_key,
          shipper: {
            id: 1,
          },
          status_convert_pre: 1,
          status_convert: 2,
          title_logorder: res.data.title_logorder,
          created_at: res.data.created_at,
          total_price: this.Order.total_price,
        });
        HTTP.post("/contact/send/", transData);
      });
      HTTP.post(stateWalletEndpoint, stateWallet);
    },
    handleConfirmPickUpOrder() {
      this.isConfirmPickOrder = true;
    },
    handlePickUpOrder() {
      const statusOrder = JSON.stringify({
        status_order: {
          status: 3,
          statusChange: 3,
          status_list: {
            id: 1,
            status: true,
            organization: 2,
            status_convert: 2,
            title_status_order: "Đang thực hiện (Chờ giao hàng)",
          },
        },
      });
      HTTP.put("/v1/api/order/" + this.$route.params.id + "/", statusOrder)
        .then(() => {
          this.$router.push({
            path: "/quan-ly-order/" + this.Order.id + "/successful-pick-up",
          });
        })
        .catch(error => console.log(error))
    },
    handleDelivery() {
      const statusOrder = JSON.stringify({
        status_order: {
          status: 4,
          statusChange: 4,
          status_list: {
            id: 1,
            status: true,
            organization: 2,
            status_convert: 2,
            title_status_order: "Đang thực hiện (đang giao hàng)",
          },
        },
      });
      HTTP.put("/v1/api/order/" + this.$route.params.id + "/", statusOrder)
        .then((res) => (this.Order = res.data))
        .catch(error => console.log(error))
      const logOrder = JSON.stringify({
        title_logorder: "Đang thực hiện (đang giao hàng)",
        note: "Note",
        user_create: localStorage.getItem("userId"),
        order: this.$route.params.id,
      });
      HTTP.post("/v1/api/logorder/", logOrder).then((res) => {
        const transData = JSON.stringify({
          key: "status_order",
          order_key: this.Order.order_key,
          shipper: {
            id: 1,
          },
          title_logorder: res.data.title_logorder,
          created_at: res.data.created_at,
          total_price: this.Order.total_price,
        });
        HTTP.post("/contact/send/", transData);
      });
    },
    handleCancel() {
      this.isCancel = true;
      const stateWallet = JSON.stringify({
        wallet: this.wallet.id,
        order: this.Order.id,
        status_exchange: 3,
        exchange_type: 1,
        payment_method: null,
      });
      HTTP.post(stateWalletEndpoint, stateWallet);
    },
    deliveryFailed() {
      const stateWallet = JSON.stringify({
        wallet: this.wallet.id,
        order: this.Order.id,
        status_exchange: 3,
        exchange_type: 1,
        payment_method: null,
      });
      HTTP.post(stateWalletEndpoint, stateWallet);
    },
    handleSuccessCancelOrder() {
      this.isSuccessCancel = false;
    },
    moveToWaitOrder() {
      this.isPickOrder = false;
    },
    handleDeliveryOrder() {
      this.Order.status_order.status = 11;
      this.isPickOrder = false;
    },
    hideNotice() {
      this.isNotice = false;
    },
    abc() {
      console.log('165125125');
    },
    handleReturnStore() {
      this.isReturnStore = true;
    },
    cancelOrder() {
      this.isReturnStore = false;
      const statusOrder = { status_order: 51 };
      HTTP.put("/v1/api/order/" + this.$route.params.id + "/", statusOrder)
        .then((res) => (this.Order = res.data))
        .catch(error => console.log(error))
    },
    handleReturnWarehouse() {
      this.isReturnWareHouse = false;
      const statusOrder = { status_order: 19 };
      HTTP.put("/v1/api/order/" + this.$route.params.id + "/", statusOrder)
        .then((res) => (this.Order = res.data))
        .catch(error => console.log(error))
    },
    confirmCancelOrder() {
      const statusOrder = { status_order: 20 };
      HTTP.put("/v1/api/order/" + this.$route.params.id + "/", statusOrder)
        .then((res) => (this.Order = res.data))
        .catch(error => console.log(error))
    },
    handleWallet() {
      const stateWallet = JSON.stringify({
        wallet: this.wallet.id,
        order: this.Order.id,
        status_exchange: 2,
        exchange_type: 1,
        payment_method: null,
      });
      HTTP.post(stateWalletEndpoint, stateWallet);
    },
  },
  created() {
    this.getData();
    this.getWallet();
   
  },
  // mounted(){
  //   this.setWidth();
  // }
};
</script>

<style scoped lang="scss">
@import "OrderDetail.scss";
</style>

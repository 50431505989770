<template>
  <div class="successful pt15 pl1 pr1">
    <div class="successful-header f fdc aic mb1">
      <div class="successful-noti"></div>
      <div class="info h2">Thông báo</div>
      <div class="pick-up h2">Bạn đã nhận hàng</div>
    </div>
    <div class="successful-media f aic jcc mb1 border-radius bg-gray-5">
      <div class="f  mt1 mb1" v-if="btnReceivetUpload">
        <label
          for="receive"
          class="h5 mr1 pt05 pb05 pl1 pr1 border-radius bg-blue white"
        >
          Tải ảnh lên
        </label>
        <div class="f fdc jcb">
          <div class="h7">Thêm ảnh</div>
          <div class="h6 gray">Dung lượng tối đa 2MB</div>
        </div>
      </div>
      <div class="f">
        <input type="file" id="receive" ref="uploadBtn" @change="showImgReceive" />
        <div class="preview f jce relative" v-if="showReceive">
          <div class="absolute icon close-img" @click="closeReceive"></div>
          <img :src="urlReceive" />
        </div>
      </div>
    </div>
    <div class="successful-note mb15">
      <div class="successful-note__title p4">Chú thích</div>
      <textarea
        class="h7 pt05 pb05 pl075 pr075"
        name=""
        id=""
        cols="30"
        rows="10"
        v-model="note"
        placeholder="Thêm ghi chú"
      ></textarea>
    </div>
    <div class="successful-rating mb1">
      <div class="successful-rating__title h8 mb1">
        Vui lòng đánh giá dịch vụ kho
      </div>
      <div class="f jcc aic mb05">
        <!-- <div class="h6">Tiêu chí 1</div> -->
        <RatingStarView></RatingStarView>
      </div>
    </div>
    <div class="successful-comment mb1">
      <div class="successful-comment__title p4">Ghi chú</div>
      <textarea
        class="h7 pt05 pb05 pl075 pr075"
        name=""
        id=""
        cols="30"
        rows="10"
        placeholder="Thêm nhận xét"
      ></textarea>
    </div>
    <div class="successful-action">
      <div class="successful-action__rules h6 mb15 align-c gray">
        Bạn có trách nhiệm bảo quản và chuyển giao kiện hàng, theo
        <span class="blue h7">Điều khoản và Điều kiện</span> của chúng tôi
      </div>
      <div class="successful-action__btn f jcb aic mb15">
        <div class="w50 h8 pt1 pb1 border align-c border-radius" @click="close">Huỷ bỏ</div>
        <div
          v-if="enableSubmit"
          @click="handleBack"
          class="w50 h8 pt1 pb1 ml05 align-c border-radius bg-red-1 white"
        >
        <div>Tôi đã hiểu</div>
          
        </div>
          <fieldset
        class="w50 h8 pt1 pb1 ml05 align-c border-radius c-red-faild white"
        v-if="disableSubmit"
        disabled
      >
        <div> Tôi đã hiểu</div>
      </fieldset>
      </div>
    </div>
  </div>
</template>

<script>
import RatingStarView from "@/components/RatingStarView/RatingStarView.vue";
import { HTTP } from "../../http-default";
export default {
  components: {
    RatingStarView,
  },
  data() {
    return {
      disableSubmit: true,
      enableSubmit: false,
      urlReceive: "",
      showReceive: false,
      btnReceivetUpload: true,
      fileUpload:"",
      note:""
    };
  },

  methods: {
    handleBack() {
      var data = new FormData()
      data.append('title_logorder',"Đang thực hiện (chờ giao hàng)")
      data.append('note',this.note)
      data.append('user_create',parseInt(localStorage.getItem("userId")))
      data.append('order',parseInt(this.$route.params.id))
      data.append('image',this.fileUpload[0])
      // const logOrder = JSON.stringify({
      //   title_logorder: "Đang thực hiện (chờ giao hàng)",
      //   note: "Note",
      //   user_create: localStorage.getItem("userId"),
      //   order: this.$route.params.id,
      // });
      HTTP.post("/v1/api/logorder/", data).then((res) => {
        const transData = JSON.stringify({
          key: "status_order",
          order_key: this.Order.order_key,
          shipper: {
            id: 1,
          },
          title_logorder: res.data.title_logorder,
          created_at: res.data.created_at,
          total_price: this.Order.total_price,
        });
        HTTP.post("/contact/send/", transData);
      });
      this.$router.go(-1);
    },
    showImgReceive(e) {
      let files = this.$refs.uploadBtn.files
      this.fileUpload = files
      console.log(this.fileUpload[0],'fileload');
      this.btnReceivetUpload = false;
      this.showReceive = true;
      var src = URL.createObjectURL(e.target.files[0]);
      this.urlReceive = src;
      if (e.target.files.length > 0) {
        this.enableSubmit = true;
        this.disableSubmit = false;
      }
    },
    close(){
      this.$router.go(-1);
    },
    closeReceive() {
      this.urlReceive = "";
      this.btnReceivetUpload = true;
      this.showReceive = false;
      this.enableSubmit = false;
      this.disableSubmit = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "SuccessfulPickUp.scss";
</style>
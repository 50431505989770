import firebase from 'firebase'
import "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyCk6Z96bgG30JS8s7GBLFTAKSp9Qj9JSls",
  authDomain: "nmecomerce.firebaseapp.com",
  databaseURL: "https://nmecomerce-default-rtdb.firebaseio.com",
  projectId: "nmecomerce",
  storageBucket: "nmecomerce.appspot.com",
  messagingSenderId: "330123273128",
  appId: "1:330123273128:web:20f851bd3572c18b4b739d",
  measurementId: "G-XNFYMPNVLQ"
};

// Initialize Firebase
export default firebase.initializeApp(firebaseConfig);
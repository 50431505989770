<template>
  <div class="mt2 order-list">
    <div class="pl1 pr1 order__filter">
      <div
        v-for="(status, index) in orderStateList"
        :key="index"
        @click="filterOrders(status.value, index)"
        ref="stateOrder"
        class="filter__item border-radius mr05 bg-red-2"
      >
        <div class="filter-select-text h7">
          {{ status.name }}
        </div>
      </div>
    </div>

    <div class="container">
      <div v-for="(item, key) in finalOrdersList1" :key="key">
        <router-link
          :to="{
            name: 'detail-order',
            params: { id: item.id, status: filterOrderStatus },
          }"
        >
          <div class="order__item mt15 border border-radius bg-white-1">
            <div
              class="item__header f jcb aic pt075 pb075 pr1 pl1 border-bottom"
            >
              <div class="w50 f jcb aic">
                <h3 class="h7 blue">#{{ item.order_key }}</h3>
                <!-- <p class="black h7 order-list__order">{{ item.order_key }}</p> -->
              </div>
              <div
                class="h7 border border-radius pt025 pb025 pl05 pr05"
                v-if="item.status_order.status == 1"
              >
                Chờ nhận
              </div>
              <div
                class="h7 border-radius pt025 pb025 pl05 pr05 orange bg-yellow"
                v-if="item.status_order.status == 2 || item.status_order.status == 3 || item.status_order.status == 4"
              >
                Đang thực hiện
              </div>
              <div
                class="h7 border-radius pt025 pb025 pl05 pr05 bg-green green"
                v-if="item.status_order.status == 5"
              >
                Hoàn Thành
              </div>
            </div>
            <div class="item__info pt075 pr1 pl1">
              <div class="info__item f jcb">
                <div class="h6 gray">Địa chỉ khách</div>
                <div class="h7 w50 align-e">
                  {{ item.customer.data.address }}
                </div>
              </div>
              <div class="info__item f jcb">
                <div class="h6 gray">Địa chỉ kho</div>
                <div class="h7 w50 align-e">
                  <!-- {{ item.customer.data.full_name }} -->
                  48 Tố Hữu
                </div>
              </div>
              <div class="info__item f jcb">
                <div class="h6 gray">Tổng tiền hàng</div>
                <div class="title-price h7 w50 align-e">
                  {{ item.total_price }}
                </div>
              </div>
            </div>
            <div class="item__total f jcb aic pt1 pb1 pl1 pr1 bg-gray-3">
              <div class="item__total-price">
                <div class="mb05 h6 gray">Lợi nhuận</div>
                <div class="h3">
                  <!-- {{ item.shipper_profit }} -->
                  35.000VND
                </div>
              </div>
              <div
                class="
                  item__total-btn
                  h5
                  pt05
                  pb05
                  border border-radius
                  bg-white
                "
              >
                Xem chi tiết
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "../../http-default";
const endpoint = "/v1/api/order/";
const IS_ACTIVE = "is-active";

export default {
  props: {
    dataOrder: {
      type: Array,
      default() {
        return [];
      },
    },
    dataStatusOrder: {
      type: Array,
      default() {
        return [];
      },
    },
    ListOrder:{
      type: Array,
       default() {
        return [];
      },
    },
    finalOrdersList:{
      type: Array,
       default() {
        return [];
      },
    }
  },
  watch:{
    ListOrder(){
      if(this.ListOrder != ""){
       this.finalOrdersList1 =  this.ListOrder 
      }
      else{
        this.finalOrdersList1 =  this.finalOrdersList
      }
    }
  },
  data() {
    return {
      finalOrdersList1:[],
      orderStateList: [
        {
          value: "all",
          name: "Tất cả",
        },
        {
          value: 1,
          name: "Chờ nhận",
        },
        {
          value: 2,
          name: "Đang thực hiện",
        },
        {
          value: 5,
          name: "Hoàn thành",
        },
        {
          value: "cancel",
          name: "Đã huỷ",
        },
      ],
    };
  },
  computed: {
    filterOrderStatus() {
      return this.orderStateList.filter((item) => item.active === true);
    },
  },
  mounted() {
    this.setDefaultOrderState();
  },
  methods: {
    // getData() {
    //   HTTP.get(endpoint).then((res) => {
    //     this.dataOrder = res.data.results;
    //     this.finalOrdersList = res.data.results;
    //     for (let i = 0; i < this.dataOrder.length; i++) {
    //       this.dataOrder[i].deposit_amount = this.dataOrder[
    //         i
    //       ].deposit_amount.toLocaleString("it-IT", {
    //         style: "currency",
    //         currency: "VND",
    //       });
    //       this.dataOrder[i].shipper_profit = this.dataOrder[
    //         i
    //       ].shipper_profit.toLocaleString("it-IT", {
    //         style: "currency",
    //         currency: "VND",
    //       });
    //       this.dataOrder[i].total_price = this.dataOrder[
    //         i
    //       ].total_price.toLocaleString("it-IT", {
    //         style: "currency",
    //         currency: "VND",
    //       });
    //     }
    //   });
    // },
    filterOrders(status, index) {
      this.$refs.stateOrder.map((state) => state.classList.remove(IS_ACTIVE));
      this.$refs.stateOrder[index].classList.add(IS_ACTIVE);
      if (status === "all") this.finalOrdersList1 = [...this.dataOrder];
      else
        this.finalOrdersList1 = this.finalOrdersList.filter(
          (order) => order.status_order.status == status);
    },
    setDefaultOrderState() {
      this.$refs.stateOrder[0].classList.add(IS_ACTIVE);
    },
  },
  // created() {
  //   this.getData();
  // },
};
</script>

<style scoped lang="scss">
@import "OrderList.scss";
</style>
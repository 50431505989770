<template>
    <div class="delivery-fail bg-gray-1 h100vh">
        <div class="delivery-fail__header w100 f jcb aic pt075 pb075 pl1 pr1 bg-white-1">
            <div 
                class="f aic"
                @click="handleBack">
                <div class="delivery-fail__header-back icon icon--back"></div>
                <div class="h5">Quay lại</div>
            </div>
            <div 
                class="delivery-fail__header-call f aic">
                <a class="h8 blue mr05" href="tel:0987654321">Gọi khách</a>
                <div class="icon icon--phone"></div>
            </div>
        </div>

        <div class="delivery-fail__body pt15 pl1 pr1">
            <div class="body__title f fdc jcc aic mb15">
                <div class="body__title-code p6 mb05">
                    Đơn hàng #12345678
                </div>
                <div class="body__title-status h2 red-2-text">
                    không thành công
                </div>
            </div>
            <div class="body__select mb1">
                <select v-model="reason" class="w100 h6 pt075 pb075 pl075 pr075">
                    <option value="">Chọn một lý do ...</option>
                    <option value="Khách không nghe máy">Khách không nghe máy</option>
                    <option value="Khách từ chối nhận hàng">Khách từ chối nhận hàng</option>
                </select>
            </div>
            <div class="body__media f aic mb1 border-radius bg-gray-5">
                <div class="h5 mr1 pt05 pb05 pl1 pr1 border-radius bg-blue white">Tải ảnh lên</div>
                <div class="f fdc jcb">
                    <div class="h7">Thêm ảnh</div>
                    <div class="h6 gray">Dung lượng tối đa 2MB</div>
                </div>
            </div>
            <div class="body__note mb15">
                <div class="body__note-title p4">
                    Chú thích
                </div>
                <textarea v-model="note" class="h7 pt05 pb05 pl075 pr075" name="" id="" cols="30" rows="10" placeholder="Thêm ghi chú cho đơn hàng của bạn"></textarea>
            </div>
        </div>

        <div class="delivery-fail__btn w100 f jcb aic bg-white-1">
            <div class="w50 h8 pt1 pb1 border align-c border-radius">Huỷ bỏ</div>
            <div @click="handleSubmit" class="w50 h8 pt1 pb1 ml05 align-c border-radius bg-red-1 white">Gửi</div>
        </div>

        <!-- Pop-up Noti -->
        <div class="pop-up f jcc aic">
            <div class="noti f fdc aic border-radius bg-white-1">
                <div class="noti__img successful-noti mb1"></div>
                <div class="noti__header h2 mb05">Thông báo</div>
                <div class="noti__title p6 mb1">Gửi thông tin thành công.</div>
                <div class="noti__btn w100 f fw jcb aic">
                    <button @click="handleReturnWarehouse" class="w100 h8 pt1 pb1 border align-c border-radius">Trả hàng về kho & giao lại sau</button>
                </div>
                <div class="noti__btn w100 f jcb aic mt1">
                    <button @click="handlePopUp" class="w50 h8 pt1 pb1 border align-c border-radius">Giao lại sau</button>
                    <button @click="handleCancel" class="w50 h8 pt1 pb1 ml05 align-c border-radius bg-red-1 white">Huỷ nhận đơn</button>
                </div>
            </div>
        </div>
        <NoticeView v-if="isCancel">
            <template v-slot:header>
                <h2 class="h2 red-1">Thông báo</h2>
            </template>
            <template>
                <div class="p6 gray">
                    Chọn <strong class="h5 black">“Xác nhận”</strong> tương ứng với việc <strong class="h5 black">Hủy đơn hàng</strong> và bạn đang đồng ý với các Điều khoản và Điều kiện của chúng tôi
                </div>
            </template>
            <template v-slot:footer>
                <button @click="handleBack" class="button button--white notice__cta">Quay lại</button>
                <button @click="handleConfirmCancel" class="button button--primary notice__cta">Xác nhận</button>
            </template>
        </NoticeView>
        <NoticeView v-if="isConfirmCancel">
            <template v-slot:header>
                <h2 class="h2 red-1">Thông báo</h2>
            </template>
            <template>
                <div class="p6 gray">Huỷ đơn thành công</div>
            </template>
            <template v-slot:footer>
                <button @click="handleConfirm" class="button button--white notice__cta">Đóng</button>
            </template>
        </NoticeView>
    </div>
</template>

<script>
import { HTTP } from '@/http-default'
import NoticeView from '@/components/NoticeView/NoticeView.vue'

export default {
    components: {
        NoticeView
    },
    data() {
        return {
            data : [],
            dataOrder: [],
            reason: '',
            note: '',
            isCancel: false,
            isConfirmCancel: false
        }
    },
    methods: {
        getData() {
            HTTP.get('/v1/api/order/')
            .then((res) => {
                this.data = res.data.results
                for (let i = 0; i < this.data.length; i++) {
                    if (this.data[i].id == this.$route.params.id) {
                        this.dataOrder = this.data[i]
                    }
                }
            })
        },
        handleBack() {
            this.$router.go(-1)
        },
        handlePopUp() {
            var popUp = document.getElementsByClassName('pop-up')[0]
            popUp.classList.toggle('active')
        },
        handleSubmit() {
            const statusOrder = JSON.stringify({
                status_order: {
                status: 5,
                statusChange: 5,
                status_list: {
                    id: 1,
                    status: true,
                    organization: 5,
                    status_convert: 5,
                    title_status_order: "Đang thực hiện (cần đi lấy hàng)",
                }
                },
            });
            HTTP.put('/v1/api/order/' + this.$route.params.id + '/', statusOrder) 
            .then(() => this.handlePopUp())
            .catch(error => console.log(error))
        },
        handleReturnWarehouse() {
            const statusOrder = { "status_order" : 18}
            HTTP.put('/v1/api/order/' + this.$route.params.id + '/', statusOrder) 
            .then(() => this.$router.push({ path: "/quan-ly-order/" + this.dataOrder.id }))
            .catch(error => console.log(error))
        },
        handleConfirm() {
            const statusOrder = { "status_order" : 20}
            HTTP.put('/v1/api/order/' + this.$route.params.id + '/', statusOrder) 
            .then(() => this.$router.push({ path: "/quan-ly-order/" + this.dataOrder.id }))
            .catch(error => console.log(error))
        },
        handleCancel() {
            this.handlePopUp()
            this.isCancel = true
        },
        getDate() {
            const currentdate = new Date()
            return currentdate.getDate()
                + (currentdate.getMonth()+1)
                + currentdate.getFullYear()
                + currentdate.getHours()
                + currentdate.getMinutes()
                + currentdate.getSeconds().toString()
        },
        handleConfirmCancel() {
            const payload = {
                "title_logorder": this.reason,
                "created_at": "2022-05-23T16:48:15Z",
                "updated_at": this.getDate(),
                "note": this.note,
                "order": this.dataOrder.id,
                "user_create": this.dataOrder.user_create,
                "media": [
                    2
                ]
            }
            this.isCancel = false
            HTTP.post('/v1/api/logorder/', payload) 
            .then(() => this.isConfirmCancel = true)
            .catch(error => console.log(error))
        },
   
    },
    created() {
        this.getData()
    }
}
</script>

<style scoped lang="scss">
    @import "DeliveryFail.scss";
</style>    
const STATUS_ORDER = [
    {
        status_oder: 1,
        status_name: "Waiting"
    },
    {
        status_oder: 2,
        status_name: "Progress"
    }
]

export {STATUS_ORDER}
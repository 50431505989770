<template>
  <div
    v-if="userData"
    class="person__acc-wrapp relative w100 mt1"
  >
    <div class="person__acc border-radius bg-white-1 pt1 pr1 pb1 pl1 mb1">
      <div class="person__acc-title h8 mb1 text-red-linear">
        Thông tin cá nhân
      </div>
      <div class="person__acc-img f fdc aic">
        <div class="person__acc-img-wrap f jcc">
          <div class="person__acc-img-link w100 mb05">
            <div>
              <div class="avatarImg">
                  <div class="w100" v-if="userData.avatar">
                    <img :src="userData.avatar" >
                  </div>
                  <div class="w100" v-else-if="urlAva">
                    <img :src="urlAva" />
                  </div>
                  <div v-else>
                    <img class="w100" src="../../../assets/Account/avatar-1.svg" />
                  </div>
              </div>
              <label for="changeAva">
                <div class="w15 relative person__acc-img-camera" >
                  <img
                    class="w100 absolute"
                    src="../../../assets/Account/cam-back.svg"
                  />
                  <img
                    class="w100 absolute"
                    src="../../../assets/Account/camera-1.svg"
                  />
                </div>
              </label>
              <input class="hidenInput" type="file" id="changeAva" ref="uploadBtn"  @change="showAvatar" />
            </div>
          </div>
        </div>
        <div class="person__acc-img-add p7 mb1">Kích thước tối đa &#60; 2M</div>
      </div>
      <div class="person__acc-log">
        <div class="person__acc-name">
          <div class="p4 mb05">Họ và tên</div>
          <input
            class="pt075 pr075 pb075 pl075 p4 mb1 w100"
            type="text"
            v-model="personFullName"
          />
        </div>
        <div class="person__acc-pass">
          <div class="p4 mb05">Số điện thoại</div>
          <input
            class="pt075 pr075 pb075 pl075 p4 mb05 w100"
            type="text"
            autocomplete="off"
            :value="userData.phone"
            disabled
          />
        </div>
        <div class="person__acc-pass">
          <div class="p4 mb05">Email</div>
          <input
            class="pt075 pr075 pb075 pl075 p4 mb05 w100"
            type="text"
            autocomplete="off"
            placeholder="bob@example.com"
            :value="userData.email"
            disabled
          />
        </div>
        <div class="person__acc-sex mb05">
          <div class="p4 mb05">Giới tính</div>
          <div
            @click="openChooseGender"
            class="person__acc-sex-box w100 f aic h6 relative"
          >
            <div class="f aic jcb w100">
              <div
                v-if="userData && personGender"
                :key="personKey"
                class="person__acc-urSex h6"
                ref="nameGender"
              >
                {{personGender.type}}
              </div>
              <div
                v-else
                class="person__acc-urSex h6"
              >
                Chọn giới tính của bạn
              </div>
              <div class="f choose--sex fdr aic pt025 pb025">
                <span class="person__icon-separate mr075">|</span>
                <div
                  class="person__icon-back icon icon--back mr025"
                  :class="{ swingIcon: active }"
                ></div>
              </div>
            </div>
            <div
              v-show="isChooseGender"
              :class="['person', { 'person__acc-choice mt025': active }]"
              ref="menuChooseSex"
            >
              <p
                class="pt05 pb05 ml1 mr1 showP h7"
                v-for="choice in choices"
                :key="choice.id"
                @click="chooseGender"
                :class="{ hideP: active }"
              >
                {{ choice.type }}
              </p>
            </div>
          </div>
        </div>
        <div class="person__acc-date">
          <div class="p4 mb05">Ngày sinh</div>
          <div>
             <DatePicker
            :placeholder="formatBirthday(userData.birthday)"
            :format="formatDate"
            input-class="w100 h6 pt075 pr075 pb075 pl075 mb1 relative "
            v-model="personBirthday"
            >
               <div
                class="person__icon-back icon icon--back mr025 absolute "
                :class="{ swingIcon: active }"
              ></div>
            </DatePicker>
          </div>
        </div>
        <div class="person__acc-address">
          <div class="p4 mb05">Địa chỉ</div>
          <input
            class="pt075 pr075 pb075 pl075 h6 mb1 w100"
            type="text"
            placeholder="Nhập địa chỉ của bạn"
            v-model="personAddress"
          />
        </div>
        <div  class="person__acc-credit">
          <div class="p4 mb05">Số CCCD/CMND</div>
          <div class="person__acc-creDetail f pt075 pr075 pb075 pl075 p4 mb1 w100">
            <div class="p4 gray">{{userData.identify_card}}</div>
          </div>
          <div class="p4 mb05">Ảnh CCCD/CMND</div>
          <!-- mắt trước -->
          <div class="grid grid--2">
            <div class="grid__item">
                <div class="body__card-title f jcc fw c-gray-back "  v-if="showFontUpload">
                  <label
                    for="fontImg"
                    class=" p7 pt05 pb05 pl1 pr1 f border-radius bg-blue white aib"
                  >
                    Tải ảnh lên
                  </label>
                  <div class="f fdc jcb">
                    <div class="p7 gray align-c">Dung lượng tối đa 2MB</div>
                  </div>
              </div>
              <div class="succsesspreview ">
                <input type="file" id="fontImg" ref="uploadFontBtn" @change="showFont" />
                <div class="succsesspreview__show f jce relative" v-if="fontUpload" >
                  <div class="absolute icon close-img" @click="closeFont" ></div>
                  <div  v-if="userData.front_card_image">
                      <img :src="userData.front_card_image" >
                  </div>
                  <div v-else-if="urlFont">
                     <img :src="urlFont" />
                  </div>
                </div>
              </div>
              <div class="titleFont f jcc">
                <p class="p6">Mặt trước</p>
              </div>
            </div>
          <!-- mặt sau -->
            <div  class="grid__item">
              <div class="body__card-title f jcc fw c-gray-back "  v-if="showBackUpload">
                <label
                  for="backImg"
                  class=" p7 pt05 pb05 pl1 pr1 f border-radius bg-blue white aib"
                >
                  Tải ảnh lên
                </label>
                <div class="f fdc jcb">
                  <div class="p7 gray align-c">Dung lượng tối đa 2MB</div>
                </div>
              </div>
              <div class="succsesspreview ">
                <input type="file" id="backImg" ref="uploadBackBtn"  @change="showBack" />
                <div class="succsesspreview__show f jce relative" v-if="backUpload" >
                  <div class="absolute icon close-img" @click="closeBack" ></div>
                  <div  v-if="userData.back_card_image">
                      <img :src="userData.back_card_image" >
                  </div>
                  <div v-else-if="urlBack">
                     <img :src="urlBack" />
                  </div>
                </div>
              </div>
              <div class="titleBack f jcc">
                <p class="p6">Mặt sau</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="person__acc-vehicle">
        <div class="p4 mb1 mt1">Phương tiện sử dụng</div>
        <div v-if="finalVehiclesList.length" class="person__acc-vehicleRender">
          <div v-for="(vehicle, index) in finalVehiclesList" :key="vehicle.id">
            <div class="person__acc-vehicleType f mb05">
              <div class="h6">Loại xe</div>
              <div class="h7">{{ showVehicleInfo(vehicle) }}</div>
            </div>
            <div class="person__acc-vehicleNum f mb1">
              <div class="h6">Biển kiểm soát</div>
              <div class="h7">{{ vehicle.license_plate }}</div>
            </div>
            <div
              class="person__acc-vehicleDefault f aic pb1 border-2 mb1"
              @click="setDefaultVehicle(vehicle, index)"
            >
              <div class="mr05 p7">
                {{ vehicle.status ? "Mặc định" : "Đặt làm mặc định" }}
              </div>
              <div class="toggleWrapper">
                <input
                  ref="toggleState"
                  type="checkbox"
                  :name="`toggle${index}`"
                  class="mobileToggle"
                  :id="`toggle${index}`"
                  :checked="vehicle.status"
                />
                <label :for="`toggle${index}`"></label>
              </div>
            </div>
          </div>
        </div>
        <div
            v-if="vehiclesType.length"
            class="person__vehicle-text textcolor-1 f aic mb1"
            @click="addNewVehicle()"
          >
            <button class="icon icon--add none-padd mr05 f aic"/>
            <div>Thêm phương tiện...</div>
          </div>
        <div
          v-if="isAddTypeCar"
          class="person__acc-vehicleAdd mb1 p4">
          <div class="person__acc-vehicleContain">
            <div class="person__acc-sex mb05">
              <div class="person__acc-vehicleType">
                <div class="p4 mb05">Loại xe</div>
                <div
                  @click="chooseTypeCar"
                  class="person__acc-typeBox f jcb h6 border pt075 pr075 pb075 pl075 border-r4 relative">
                  <div class="typeOfVehicle">Chọn loại xe</div>
                  <div class="f">
                    <div class="mr05">|</div>
                    <div class="icon icon--back icon--back-two" />
                  </div>
                  <div
                    v-if="isChooseType"
                    class="person__acc-vehicleItem border mt025 border-r4 bg-white"
                  >
                    <div
                      class="h7 border-2"
                      v-for="(item, index) in vehiclesType"
                      :key="index"
                      @click="handleTypeVehicle(item)"
                    >
                      {{ item.title_vehicle_type }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="person__acc-typeWrap">
                <div class="person__acc-typeItem border border-r4 mt025 w100 pr075 pl075">
                  <p
                    v-for="(item, index) in finalVehiclesList"
                    :key="index"
                    class="pb075 pt075 h7 border-2"
                  >
                    {{ item.title_vehicle_type }}
                  </p>
                </div>
              </div>

              <div class="person__acc-address">
                <div class="p4 mb05 mt1">Biển kiểm soát</div>
                <input
                  class="pt075 pr075 pb075 pl075 h6 mb1 w100"
                  type="text"
                  placeholder="Nhập biển số xe"
                  v-model="numberPlate"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="person__vehicle-history p4 mb15 textcolor-1">
          <router-link
            :to="{ path: 'my-review' }"
            class="blue"
          >
          Lịch sử đánh giá của bạn
          </router-link>
        </div>
        <div class="grid grid--2 person__acc-vehicleBtn f">
          <div class="grid__item">
            <button
              @click="confirmReset"
              class="w100 button button--white h5 border-1 border-radius"
            >
              Đặt lại
            </button>
          </div>
          <div class="grid__item">
            <button
              @click="updatePersonalInfo"
              class="w100 button button--primary h5 border-1 border-radius"
            >
              Cập nhật
            </button>
          </div>
        </div>
      </div>
    </div>
    <NoticeView v-if="isReset">
      <template>
        <h2 class="h2 red-1">Thông báo</h2>
      </template>
      <p class="p6 gray align-c">Xác nhận đặt lại dữ liệu đã thay đổi?</p>
      <template v-slot:footer>
        <button @click="handleBack" class="button button--white notice__cta">
          Quay lại
        </button>
        <button @click="handleReset" class="button button--primary notice__cta">
          Xác nhận
        </button>
      </template>
    </NoticeView>
    <NoticeView v-if="isUpdateSuccess">
      <template>
        <div class="noti__img successful-noti mb1"></div>
        <h2 class="h2 red-1">Thông báo</h2>
      </template>
      <p class="p6 black align-c">Bạn đã update thành công.</p>
      <template v-slot:footer>
        <button @click="closeNotice" class="button button--white notice__cta">
          Đóng
        </button>
      </template>
    </NoticeView>
    <NoticeView v-if="isWarning" ref="noticeWarning">
      <div class="mt2 mb2 error-notice">
        <span class="error-icon"></span>
      </div>
      <h2 class="h2 red-1">Thông báo</h2>
      <p class="p6 black align-c">Bạn cần chọn loại xe.</p>
      <template v-slot:footer>
        <button @click="closeNoticeWarning" class="button button--white notice__cta">
          Đóng
        </button>
      </template>
    </NoticeView>
  </div>
</template>

<script>
import DatePicker from "vuejs-datepicker";
import NoticeView from "@/components/NoticeView/NoticeView.vue";
import { HTTP } from '@/http-default'
import dateFormat from 'dateformat'
import { isEmptyObj } from '@/lib/utils'
const endpoint = '/v1/api/vehicle/'
const endpointShipper = '/v1/api/shipper/'
const SHIPPER_ID = localStorage.getItem('shipper')

export default {
  name: "PersonAcc",
  components: {
    DatePicker,
    NoticeView,
  },
  props: {
    vehiclesType: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      //cmnd upload
      showFontUpload:true,
      showBackUpload: true,
      fontUpload: false,
      backUpload:false,
      urlFont:"",
      urlBack:"",
      fileFont:"",
      fileBack:"",
      personKey: 0,
      userData: null,
      personFullName: null,
      personGender: null,
      personAddress: null,
      personBirthday: null,
      formatDate: "dd/MM/yyyy",
      isWarning: false,
      active: false,
      choices: [
        { id: 1, value: 1, type: "Nam" },
        { id: 2, value: 2, type: "Nữ" },
        { id: 3, value: 3, type: "Giới tính thứ ba" },
      ],
      isChooseGender: false,
      isChooseType: false,
      isReset: false,
      numberPlate: '',
      typeVehicle: null,
      isAddTypeCar: false,
      myVehicle: [],
      finalVehiclesList: [],
      defaultVehicle: '',
      vehicleStatus: {},
      isUpdateSuccess: false,
      urlAva:"",
      avatarUpload:""
    }
  },
  async created() {
    await this.getUser()
    this.getVehiclesList()
    this.getGender()
  },
  mounted(){
      if(this.userData.front_card_image !== "" || this.userData.back_card_image !== ""){
        this.showFontUpload = false;
        this.fontUpload = true;
        this.showBackUpload = false;
        this.backUpload = true;
      }
  },
  methods: {
    //upload CMND
    showFont(e){
      let filefont  = this.$refs.uploadFontBtn.files
      this.fileFont = filefont
      console.log(this.fileFont[0],'fileFont');
      this.showFontUpload = false;
      this.fontUpload = true;
       var src = URL.createObjectURL(e.target.files[0]);
      this.urlFont = src;
    
    },
    showBack(e){
      let fileback = this.$refs.uploadBackBtn.files
      this.fileBack = fileback
      this.showBackUpload = false;
      this.backUpload = true;
       var src = URL.createObjectURL(e.target.files[0]);
      this.urlBack = src;
    },
    closeFont(){
      this.urlFont = "";
      this.showFontUpload = true;
      this.fontUpload = false;
    },
    closeBack(){
      this.urlBack = "";
      this.showBackUpload = true;
      this.backUpload = false;
    },
    // showF(){
    //     this.showFontUpload = false;
    //     this.fontUpload = true;
    // },
    // showB(){
    //     this.showBackUpload = false;
    //     this.backUpload = true;
    // },
    showAvatar(e){
      let files = this.$refs.uploadBtn.files
      this.avatarUpload = files 
      var src = URL.createObjectURL(e.target.files[0]);
      this.urlAva = src;
    },
    async getUser() {
      const response = await HTTP.get(endpointShipper)
      this.userData = response.data.results[0]
      this.personFullName = this.userData.full_name
      this.personAddress = this.userData.address
      
      if(this.userData.front_card_image == "" || this.userData.back_card_image == ""){
        this.showFontUpload = true;
        this.fontUpload = false;
         this.showBackUpload = true;
        this.backUpload = false;
      }
      //   if(this.userData.front_card_image !== "" || this.userData.back_card_image !== ""){
      //   this.showFontUpload = false;
      //   this.fontUpload = true;
      //   this.showBackUpload = false;
      //   this.backUpload = true;
      // }
      
    },
    async getVehiclesList() {
      const response = await HTTP.get(endpoint)
      this.finalVehiclesList = SHIPPER_ID ? response.data.results.filter(item => item.shipper == SHIPPER_ID) : []
    },
    openChooseGender() {
      this.active = !this.active;
      this.isChooseGender = !this.isChooseGender;
    },
    getGender() {
      if(this.userData.gender ==  null){
         this.personGender = this.choices.find(choice => choice.value === null)
         console.log(this.personGender,"duo2" );
      }
      else{
        this.personGender = this.choices.find(choice => choice.value === this.userData.gender)
      }
      
      
    },
    chooseGender(event) {
      let genderName = event.target.textContent.trim()
      if(this.$refs.nameGender){
        this.$refs.nameGender.innerHTML = genderName
        if(genderName == 'Nam') {
          const obj = {
            id: 1,
            value: 1,
            type: 'Nam'
          }
          this.personGender = Object.assign({}, obj)
        } else if(genderName == 'Nữ') {
          const obj = {
            id: 2,
            value: 2,
            type: 'Nữ'
          }
          this.personGender = Object.assign({}, obj)
        }  else {
          const obj = {
            id: 3,
            value: 3,
            type: 'Giới tính thứ ba'
          }
          this.personGender = Object.assign({}, obj)
        }
      }
    },
    chooseTypeCar() {
      this.isChooseType = !this.isChooseType;
    },
    handleTypeVehicle(item) {
      let val = item.title_vehicle_type;
      this.typeVehicle = item.id
      document.querySelector(".typeOfVehicle").innerHTML = val;
    },
    confirmReset() {
      this.isReset = true;
    },
    handleBack() {
      this.isReset = false;
    },
    resetChooseVehicle() {
      this.isAddTypeCar = false
      this.numberPlate = ''
    },
    handleReset() {
      this.personKey++
      this.isReset = false
      this.getUser()
      this.resetChooseVehicle()
      this.personBirthday = this.userData.birthday
      this.personGender = this.choices.find(choice => choice.value === this.userData.gender)
    },
    addNewVehicle() {
      this.isAddTypeCar = !this.isAddTypeCar
    },
    handleAddNewVehicle() {
      const payload = {
        "license_plate": this.numberPlate,
        "status": false,
        "vehicle_type": this.typeVehicle,
        "shipper": SHIPPER_ID,
        'title_vehicle_type': this.typeVehicle
      }
      if (this.numberPlate && this.typeVehicle) {
        HTTP.post(endpoint, payload) 
        .then(() => {
          this.isAddTypeCar = !this.isAddTypeCar
          this.getVehiclesList()
        })
        .catch(error => console.log(error))
      }
    },
    async handleVehicleStatus() {
      const ressponse = await HTTP.get(endpoint)
      const listVehicles = ressponse.data.results
      const currentVehicleActive = listVehicles.find(vehicle => vehicle.status === true)
      const setStatusDisable = {status: false}
      if(currentVehicleActive && isEmptyObj(this.vehicleStatus)) {
        const changeVehicleActive = Object.assign(currentVehicleActive, setStatusDisable)
        await HTTP.put(`${endpoint}${currentVehicleActive.id}/`, changeVehicleActive)
        .then(() => this.getVehiclesList())
        .catch(error => console.log(error))
      }
      if (isEmptyObj(this.vehicleStatus)) {
        await HTTP.put(`${endpoint}${this.vehicleStatus.id}/`, this.vehicleStatus)
        .then(() => this.getVehiclesList())
        .catch(error => console.log(error))
      }
    },
    handleInfoShipper() {
      const formatBirthday = dateFormat(this.personBirthday, 'isoDate')
      var payloadShipper = new FormData()
      payloadShipper.append('full_name',this.personFullName)
      payloadShipper.append('birthday',formatBirthday)
      payloadShipper.append('address',this.personAddress)
      payloadShipper.append('gender',this.personGender.value)
      payloadShipper.append('front_card_image',this.fileFont[0])
      payloadShipper.append('back_card_image',this.fileBack[0])
      payloadShipper.append('avatar',this.avatarUpload[0])
      if (this.personFullName || formatBirthday || this.personAddress || this.personGender) {
        HTTP.put(`${endpointShipper}${SHIPPER_ID}/`, payloadShipper)
        .then(res => {
          this.userData = res.data
          this.resetChooseVehicle()
          this.isUpdateSuccess = true
        })
        .catch(error => console.log(error))
      }
    },
    updatePersonalInfo() {
      console.log('1');
      if(this.typeVehicle === null && this.numberPlate) {
        this.isWarning = true
         console.log('2');
      } else {
         console.log('3');
        this.handleAddNewVehicle()
        this.handleVehicleStatus()
        this.handleInfoShipper()
      }
    },
    showVehicleInfo(vehicle) {
      return this.vehiclesType.find(vehicleType => vehicleType.id == vehicle.vehicle_type).title_vehicle_type
    },
    setDefaultVehicle(vehicle, index) {
      this.$refs.toggleState.map(item => {
        item.checked = false
        vehicle.status = false
      })
      vehicle.status = true
      this.$refs.toggleState[index].checked = true
      this.vehicleStatus = {...vehicle}
    },
    formatBirthday(date) {
      return dateFormat(date, 'dd/mm/yyyy')
    },
    handleGender(gender) {
      return gender.value === 'nam' ? 1 : gender.value === 'nữ' ? 2 : 3
    },
    closeNotice() {
      this.isUpdateSuccess = false
    },
    closeNoticeWarning() {
      this.isWarning = false
    }
  },
};

</script>

<style scoped lang="scss">
@import "PersonAcc.scss";
</style>
<template>
    <div class="my-review">
        <div class="person__acc-history">
            <div class="history__back pt075 pb075 f aic" @click="back()">
                <div class="ml15">
                <img src="@/assets/Account/back.svg" />
                </div>
                <div class="h5 ml1">Quay lại</div>
            </div>
            <div class="history__content bg-gray-1 pt15 pr1 pl1 h100vh">
                <div class="f mb15">
                    <!-- <div class="border-radius pt025 pr05 pb025 pl05 mr1 h7 text-red-linear">
                        Kho
                    </div> -->
                    <div class="border-radius pt025 pr05 pb025 pl05 h7 c-red-back textcolor-3 ">
                        Kho đánh giá bạn
                    </div>
                </div>
                <!-- <div class="c-white-back border-radius pt1 pr1 pb1 pl1 mb1">
                    <div class="text-red-linear h5 mb1">Tổng quan đánh giá chung</div>
                    <div class="f jcb mb05">
                        <div class="h6">Đánh giá</div>
                        <div class="f aic">
                        <div class="h7 mr025">4/5</div>
                        <div>
                            <img src="@/assets/Account/star.svg" />
                        </div>
                        </div>
                    </div>
                    <div class="f jcb mb05">
                        <div class="h6">Tổng số lượt đánh giá</div>
                        <div class="h7">256 lượt</div>
                    </div>
                    <div class="f jcb">
                        <div class="h6">Số đơn đã hoàn thành</div>
                        <div class="h7">327 đơn</div>
                    </div>
                </div> -->
                <div class="c-white-back border-radius pt1 pr1 pb1 pl1 mb1">
                    <div class="text-red-linear h5 mb1">Tổng quan đánh giá</div>
                    <div class="f jcb">
                            <div class="h6">Số đơn đã hoàn thành</div>
                            <div class="h7">327 đơn</div>
                    </div>
                    <div class="history__combo mb1">
                        <!-- <div class="h7 mb05">Tiêu chí 1</div> -->
                        <div class="history__left f jcb mt1">
                            <div class="history__right f fdc jcc aic">
                                <div class="h2 mb025">4.65</div>
                                <div>
                                <img src="@/assets/Account/fullsao.svg" />
                                </div>
                                <div class="p-small mt025">179 đánh giá</div>
                            </div>
                            <div class="ml05"> 
                                <div>
                                    <div class="f aic">
                                        <div class="h6 textcolor-4 mr025">5</div>
                                        <div class="mr025">
                                            <img src="@/assets/Account/star.svg" />
                                        </div>
                                        <div class="mr05 f">
                                            <img src="@/assets/Account/5sao.svg" />
                                        </div>
                                        <div class="h6 textcolor-2">155</div>
                                    </div>
                                </div>
                                <div>
                                    <div class="f aic">
                                        <div class="h6 textcolor-4 mr025">4</div>
                                        <div class="mr025">
                                            <img src="@/assets/Account/star.svg" />
                                        </div>
                                        <div class="mr05 f relative">
                                            <img src="@/assets/Account/4sao.svg" />
                                            <img
                                                src="@/assets/Account/4sao_top.svg"
                                                class="absolute"
                                            />
                                        </div>
                                        <div class="h6 textcolor-2">20</div>
                                    </div>
                                </div>
                                <div>
                                    <div class="f aic">
                                        <div class="h6 textcolor-4 mr025">3</div>
                                        <div class="mr025">
                                            <img src="@/assets/Account/star.svg" />
                                        </div>
                                        <div class="mr05 f relative">
                                            <img src="@/assets/Account/3sao.svg" />
                                            <img
                                                src="@/assets/Account/3sao_top.svg"
                                                class="absolute"
                                            />
                                        </div>
                                        <div class="h6 textcolor-2">1</div>
                                    </div>
                                </div>
                                <div>
                                    <div class="f aic">
                                        <div class="h6 textcolor-4 mr025">2</div>
                                        <div class="mr025">
                                            <img src="@/assets/Account/star.svg" />
                                        </div>
                                        <div class="mr05 f relative">
                                            <img src="@/assets/Account/2sao.svg" />
                                            <img
                                                src="@/assets/Account/2sao_top.svg"
                                                class="absolute"
                                            />
                                        </div>
                                        <div class="h6 textcolor-2">5</div>
                                    </div>
                                </div>
                                <div>
                                    <div class="f aic">
                                        <div class="h6 textcolor-4 mr025">1</div>
                                        <div class="mr025">
                                        <img src="@/assets/Account/star.svg" />
                                        </div>
                                        <div class="mr05 f relative">
                                        <img src="@/assets/Account/1sao.svg" />
                                        <img
                                            src="@/assets/Account/1sao_top.svg"
                                            class="absolute"
                                        />
                                        </div>
                                        <div class="h6 textcolor-2">0</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="history__combo mb1">
                        <div class="h7 mb05">Tiêu chí 2</div>
                        <div class="history__left f jcb">
                        <div>
                            <div>
                            <div class="f aic">
                                <div class="h6 textcolor-4 mr025">5</div>
                                <div class="mr025">
                                <img src="@/assets/Account/star.svg" />
                                </div>
                                <div class="mr05 f">
                                <img src="@/assets/Account/5sao.svg" />
                                </div>
                                <div class="h6 textcolor-2">155</div>
                            </div>
                            </div>
                            <div>
                            <div class="f aic">
                                <div class="h6 textcolor-4 mr025">4</div>
                                <div class="mr025">
                                <img src="@/assets/Account/star.svg" />
                                </div>
                                <div class="mr05 f relative">
                                <img src="@/assets/Account/4sao.svg" />
                                <img
                                    src="@/assets/Account/4sao_top.svg"
                                    class="absolute"
                                />
                                </div>
                                <div class="h6 textcolor-2">20</div>
                            </div>
                            </div>
                            <div>
                            <div class="f aic">
                                <div class="h6 textcolor-4 mr025">3</div>
                                <div class="mr025">
                                <img src="@/assets/Account/star.svg" />
                                </div>
                                <div class="mr05 f relative">
                                <img src="@/assets/Account/3sao.svg" />
                                <img
                                    src="@/assets/Account/3sao_top.svg"
                                    class="absolute"
                                />
                                </div>
                                <div class="h6 textcolor-2">1</div>
                            </div>
                            </div>
                            <div>
                            <div class="f aic">
                                <div class="h6 textcolor-4 mr025">2</div>
                                <div class="mr025">
                                <img src="@/assets/Account/star.svg" />
                                </div>
                                <div class="mr05 f relative">
                                <img src="@/assets/Account/2sao.svg" />
                                <img
                                    src="@/assets/Account/2sao_top.svg"
                                    class="absolute"
                                />
                                </div>
                                <div class="h6 textcolor-2">5</div>
                            </div>
                            </div>
                            <div>
                            <div class="f aic">
                                <div class="h6 textcolor-4 mr025">1</div>
                                <div class="mr025">
                                <img src="@/assets/Account/star.svg" />
                                </div>
                                <div class="mr05 f relative">
                                <img src="@/assets/Account/1sao.svg" />
                                <img
                                    src="@/assets/Account/1sao_top.svg"
                                    class="absolute"
                                />
                                </div>
                                <div class="h6 textcolor-2">0</div>
                            </div>
                            </div>
                        </div>
                        <div class="history__right f fdc jcc aic">
                            <div class="h2 mb025">4.65</div>
                            <div>
                            <img src="@/assets/Account/fullsao.svg" />
                            </div>
                            <div class="h6 mt025">179 đánh giá</div>
                        </div>
                        </div>
                    </div> -->
                    <!-- <div class="history__combo mb1">
                        <div class="h7 mb05">Tiêu chí 3</div>
                        <div class="history__left f jcb">
                        <div>
                            <div>
                            <div class="f aic">
                                <div class="h6 textcolor-4 mr025">5</div>
                                <div class="mr025">
                                <img src="@/assets/Account/star.svg" />
                                </div>
                                <div class="mr05 f">
                                <img src="@/assets/Account/5sao.svg" />
                                </div>
                                <div class="h6 textcolor-2">155</div>
                            </div>
                            </div>
                            <div>
                            <div class="f aic">
                                <div class="h6 textcolor-4 mr025">4</div>
                                <div class="mr025">
                                <img src="@/assets/Account/star.svg" />
                                </div>
                                <div class="mr05 f relative">
                                <img src="@/assets/Account/4sao.svg" />
                                <img
                                    src="@/assets/Account/4sao_top.svg"
                                    class="absolute"
                                />
                                </div>
                                <div class="h6 textcolor-2">20</div>
                            </div>
                            </div>
                            <div>
                            <div class="f aic">
                                <div class="h6 textcolor-4 mr025">3</div>
                                <div class="mr025">
                                <img src="@/assets/Account/star.svg" />
                                </div>
                                <div class="mr05 f relative">
                                <img src="@/assets/Account/3sao.svg" />
                                <img
                                    src="@/assets/Account/3sao_top.svg"
                                    class="absolute"
                                />
                                </div>
                                <div class="h6 textcolor-2">1</div>
                            </div>
                            </div>
                            <div>
                            <div class="f aic">
                                <div class="h6 textcolor-4 mr025">2</div>
                                <div class="mr025">
                                <img src="@/assets/Account/star.svg" />
                                </div>
                                <div class="mr05 f relative">
                                <img src="@/assets/Account/2sao.svg" />
                                <img
                                    src="@/assets/Account/2sao_top.svg"
                                    class="absolute"
                                />
                                </div>
                                <div class="h6 textcolor-2">5</div>
                            </div>
                            </div>
                            <div>
                            <div class="f aic">
                                <div class="h6 textcolor-4 mr025">1</div>
                                <div class="mr025">
                                <img src="@/assets/Account/star.svg" />
                                </div>
                                <div class="mr05 f relative">
                                <img src="@/assets/Account/1sao.svg" />
                                <img
                                    src="@/assets/Account/1sao_top.svg"
                                    class="absolute"
                                />
                                </div>
                                <div class="h6 textcolor-2">0</div>
                            </div>
                            </div>
                        </div>
                        <div class="history__right f fdc jcc aic">
                            <div class="h2 mb025">4.65</div>
                            <div>
                            <img src="@/assets/Account/fullsao.svg" />
                            </div>
                            <div class="h6 mt025">179 đánh giá</div>
                        </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>

        <NavBarView/>
    </div>
</template>

<script>
import NavBarView from '@/components/NavBarView/NavBarView.vue'

export default {
    name: 'MyAccount',
    components: {
        NavBarView
    },
    methods: {
        back() {
            this.$router.go(-1);
        }
    }
}
</script>
<style scoped lang="scss">
@import "myreview.scss";
</style>